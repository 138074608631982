import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CreateDeliveryComponent = lazy(() => import("./CreateDelivery"));

const CreateDeliveryPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CreateDeliveryComponent />
  </Suspense>
);

export default CreateDeliveryPage;
