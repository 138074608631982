import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogOrderDetailsComponent = lazy(
  () => import("./CatalogOrderDetails")
);

const CatalogOrderDetailsPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CatalogOrderDetailsComponent />
  </Suspense>
);

export default CatalogOrderDetailsPage;
