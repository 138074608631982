import React, { useState } from "react";
import styles from "./Terms.module.scss";
import arrow from "../../../assets/IconsMerchant/Stroke 2.svg";
import { useHistory } from "react-router-dom";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { saveOnboardLevelInfo } from "../../../redux/actions/onboardlevel/onboardLevelAction";
import { getOnboardingPercentagesAction } from "../../../redux/actions/authentication/authentication";
import TermsContent from "./TermsContent";

function Terms() {
  const history = useHistory();
  const { terms_and_condition } = useSelector(
    (state) => state.onboardLevelInfoReducer.onboardLevelInfo.data
  );
  // console.log({ terms_and_condition })
  const [checked, setChecked] = useState(false);
  const [emsachecked, setEmsaChecked] = useState(false);
  const [isLinkOpened, setIsLinkOpened] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };
  const onemsaChange = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`);
    setEmsaChecked(e.target.checked);
  };
  
  const dispatch = useDispatch();

  
  const submitHandler = (identifier: boolean) => {
    dispatch(openLoader());
    client
      .post(`/subsidiary/dashboard/onboarding/accept/terms`, {
        accepted: identifier,
      })
      .then(async (res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        await getOnboardingPercentagesAction(dispatch);
        history.push("/dashboard/get-Started/onboarding");
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div
          onClick={() => history.push("/dashboard/get-Started/onboarding")}
          className={styles.wrapperInnerLeft}
        >
          <img src={arrow} alt="" />
          <p>Get started</p>
        </div>
        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>Terms and Conditions</h2>
          <p className={styles.wrapperp}>
            These are all the terms of use and conditions surrounding the Transactpay
            payment solution. Kindly reach through and accept below.
          </p>
          <div className={styles.inputwrapper}>
            <section className={styles.wrapperInnerRightContent}>
              <TermsContent></TermsContent>
              {terms_and_condition != 100 && (
              <>
                <div className={styles.radiowrapper}>
                  <p>Kindly accept the terms and conditions to proceed</p>
                  <Checkbox className={styles.wrapperp} onChange={onChange}>
                    I accept the Transactpay Business terms and conditions
                  </Checkbox>
                </div>
                {/* <div className={styles.buttonflex}>
                  <button
                    onClick={() => submitHandler(false)}
                    className={styles.cancel}
                  >
                    Decline
                  </button>

                  <button
                    disabled={!checked}
                    onClick={() => submitHandler(true)}
                    className={styles.submitHandler}
                  >
                    Accept
                  </button>
                </div> */}
              </>
              )}
            </section>
          </div>
          
          <div className={styles.inputwrapperEmsa}>
            <section className={styles.wrapperInnerEmsaContent}>
              <div>
              <h2 className={styles.wrapperh2} style={{marginTop:'20px'}}>TRANSACTWORLD DIGITAL SERVICES MERCHANT SERVICE  E-AGREEMENT</h2>
                <p className={styles.wrapperp} style={{width:'100%', textAlign:'center', fontSize:'smaller'}}>
                PLEASE READ THE FOLLOWING AGREEMENT, ALONG WITH ITS TERMS AND CONDITIONS, THE TRANSACTWORLD DIGITAL SERVICES Terms of Use and  Privacy Policy  CAREFULLY BEFORE ACCESSING OR USING THE TRANSACTWORLD DIGITAL SERVICES SERVICES. NOTE THAT THESE TERMS AND CONDITIONS CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU (THE MERCHANT) AND TRANSACTWORLD DIGITAL SERVICES  (THE SERVICE PROVIDER). 
                </p>

                  {/* <p>Click <a onClick={() => setIsLinkOpened(true)} href="https://drive.google.com/file/d/1zjVl_9hHw6U6j-G52T03HJbidK6XA9v-/view?usp=drive_link" target="_blank">here</a> to read the following agreement before accepting</p> */}
                  <section className={styles.iframeBox}>
                    <iframe src="https://drive.google.com/file/d/1zjVl_9hHw6U6j-G52T03HJbidK6XA9v-/preview" width="100%" height="100%">
                        This browser does not support PDFs. Please download the PDF to view it: <a href="path-to-your-file.pdf">Download PDF</a>.
                    </iframe>
                  </section>
                </div>
                {terms_and_condition != 100 && (
                <>
                  <div className={styles.radiowrapper}>
                    <p>Click the link to read the agreement to proceed</p>
                    <Checkbox className={styles.wrapperp} onChange={onemsaChange}>
                      I accept the  EMSA Transactpay Business terms and conditions
                    </Checkbox>
                    <p style={{margin:'20px 0'}}>Kindly accept the terms and conditions to proceed</p>
                  </div>
                  <div className={styles.buttonflex}>
                    <button
                      onClick={() => submitHandler(false)}
                      className={styles.cancel}
                    >
                      Decline
                    </button>

                    <button
                      disabled={!checked || !emsachecked}
                      onClick={() => submitHandler(true)}
                      className={styles.submitHandler}
                    >
                      Accept
                    </button>
                  </div>
                </>
                )}
              </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
