import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const EditDeliveryComponent = lazy(() => import("./EditDelivery"));

const EditDeliveryPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <EditDeliveryComponent />
  </Suspense>
);

export default EditDeliveryPage;
