import React, { useState, Fragment, useEffect } from 'react';
import styles from './Payouts.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import { ColumnPayouts, PayoutsData } from '../../types/TablesTypes/Payouts';
import TableHeader from '../../components/TableHeader/TableHeader';
import payoutsApi from '../../api/payouts';
import useApi from '../../hooks/useApi';
import { numberWithCommas } from '../../utils/formatNumber';
import moment from 'moment';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import { customStyles } from '../../constants/modalStyles';
import Modal from 'react-modal';
import InitiatePayout from '../../components/ModalContent/InitiatePayout/InitialPayout';
import client from '../../api/client';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { ToastErrorStyles } from '../../constants/toastStyles';
import PayoutMenu from '../../components/MenuOption/PayoutMenu/PayoutMenu';
import Filtered from '../../components/filterComponent/Filtered';
import { menuContent } from '../../utils/menuContent';

const Payouts = () => {
	const dispatch = useDispatch();

	const [payout, setPayout] = useState<any>();
	const [tableRow, setTableRow] = useState<any[]>();
	const [status, setStatus] = useState<string>('');
	const getPayoutsApi = useApi(payoutsApi.getPayouts);
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	const fetchPayout = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/payout/history?status=${status}`
			);
			setPayout(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};
	useEffect(() => {
		fetchPayout();
	}, [status]);

	const StatusView = ({ status, green, orange, red }: any) => {
		return (
			<div>
				{status === 'Rejected' ? (
					<div className={styles.declined}>Rejected</div>
				) : status === 'Failed' ? (
					<div className={styles.declined}>Failed</div>
				) : status === 'Declined' ? (
					<div className={styles.declined}>Declined</div>
				) : status === 'Pending' ? (
					<div className={styles.processing}>Pending</div>
				) : status === 'Processing' ? (
					<div className={styles.processing}>Processing</div>
				) : status === 'Successful' ? (
					<div className={styles.completed}>Successful</div>
				) : status === 'Approved' ? (
					<div className={styles.completed}>Approved</div>
				) : null}
			</div>
		);
	};
	const dataPayout = () => {
		const tempArr: PayoutsData[] = [];
		payout?.items
			?.slice(0)
			.reverse()
			.forEach((payout: any, index: number) => {
				tempArr.push({
					dateInitiated: moment(payout?.dateInitiated).format(
						'MMMM Do YYYY, h:mm a'
					),
					recipientName: payout?.recipientName ?? 'N/A',
					accountNumber: payout?.accountNumber ?? 'N/A',
					type: payout?.type ?? 'N/A',
					transferType: payout?.transferType ?? 'N/A',
					currency: payout?.debitCurrency ?? 'N/A',
					sessionId: payout?.sessionId ?? 'N/A',
					amount: `${payout?.debitCurrency} ${numberWithCommas(
						payout?.amount
					)}`,
					fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
					status: (
						<StatusView
							status={payout?.status}
							green={['Approved', 'Successful']}
							red={['Rejected', 'Failed', 'Declined']}
							orange={['Processing', 'Pending']}
						/>
					),
					action: <PayoutMenu  data={payout} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataPayout());
	}, [payout?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Payouts'
				dataLength={payout?.totalItems}
				data={payout?.items}
				value={value}
				setValue={setValue}
				newButton={
					<div className={styles.createLink} onClick={() => setIsOpen(true)}>
						<LinkIcon className={styles.link} />
						Initiate Payout
					</div>
				}
				dropdown={dropdown}
				setDropdown={setDropdown}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueStatus={status}
							setValueStatus={setStatus}
							payout={true}
						/>
					</>
				}
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<InitiatePayout closeModal={closeModal} fetchPayouts={fetchPayout} />
			</Modal>
			{getPayoutsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					value={value}
					columns={ColumnPayouts ? ColumnPayouts : []}
					emptyPlaceHolder='You currently do not have any payouts'
					total={payout?.totalItems}
				/>
			)}
		</Fragment>
	);
};

export default Payouts;
