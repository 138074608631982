import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogEditProductComponent = lazy(() => import("./CatalogEditProduct"));

const CatalogEditProductPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CatalogEditProductComponent />
  </Suspense>
);

export default CatalogEditProductPage;
