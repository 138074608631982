import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogProductsComponent = lazy(() => import("./CatalogProducts"));

const CatalogProductsPage = () => (
    <Suspense fallback={<LoaderPage />}>
        <CatalogProductsComponent />
    </Suspense>
)

export default CatalogProductsPage;