import React, { useState, Fragment, useEffect } from 'react';
import styles from './Refunds.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import { ColumnRefund, RefundsData } from '../../types/TablesTypes/Refunds';
import ExportButton from '../../components/ExportButton/ExportButton';
import StatusView from '../../components/StatusView/StatusView';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/drop-arrow.svg';
import refundApi from '../../api/refunds';
import useApi from '../../hooks/useApi';
import { numberWithCommas } from '../../utils/formatNumber';
import TableHeader from '../../components/TableHeader/TableHeader';
import moment from 'moment';
import RefundMenu from '../../components/MenuOption/RefundMenu/RefundMenu';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { ToastErrorStyles } from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import Filtered from '../../components/filterComponent/Filtered';
import { menuContent } from '../../utils/menuContent';
import Modal from 'react-modal';
import { computeCustomStyles, customStyles } from '../../constants/modalStyles';
import RefundTransaction from '../../components/ModalContent/RefundTransaction/RefundTransaction';

const Refunds = () => {
	const dispatch = useDispatch();

	const [value, setValue] = useState('');
	const [status, setStatus] = useState<string>('');
	const [refunds, setRefunds] = useState<any>();
	const [tableRow, setTableRow] = useState<any[]>();
	const getRefunds = useApi(refundApi.getRefunds);
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const fetchRefunds = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/dashboard/refund/all?status=${valueStatus}`
			);
			setRefunds(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};
	useEffect(() => {
		fetchRefunds();
	}, [valueStatus]);

	const dataTransaction = () => {
		const tempArr: RefundsData[] = [];
		refunds?.items
			?.slice(0)
			.reverse()
			.forEach((refund: any, index: number) => {
				tempArr.push({
					date: moment(refund?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					customerEmail: refund?.customerEmail,
					transactionAmount: `${refund?.currency} ${numberWithCommas(
						refund?.amount
					)}`,
					refundAmount: `${refund?.currency} ${numberWithCommas(
						refund?.refundAmount
					)}`,
					reference: refund?.orderReference,
					status: (
						<StatusView
							status={refund?.refundStatus}
							green='Approved || Completed'
							red='Declined'
							orange='Processing || Pending'
						/>
					),
					action: <RefundMenu data={refund} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [refunds?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Refunds'
				data={refunds?.items}
				value={value}
				setValue={setValue}
				dataLength={refunds?.totalItems}
				newButton={<div style={{ marginLeft: "20px" }} className={styles.button} onClick={() => setIsOpen(true)}>
					Log Refund
				</div>}
				dropdown={dropdown}
				setDropdown={setDropdown}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							refundfilter={true}
						/>
					</>
				}
			/>
			{getRefunds.loading ? (
				<div className={styles.loading} />
			) : (
				<>
					{/* <div className={styles.button}>Initiate Refund</div> */}
					<PaginationTable
						value={value}
						data={tableRow ? tableRow : []}
						columns={ColumnRefund ? ColumnRefund : []}
						emptyPlaceHolder='You currently do not have any refunds'
						total={refunds?.totalItems}
					/>
				</>
			)}

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={computeCustomStyles()}>
				<RefundTransaction closeModal={closeModal} />
			</Modal>
		</Fragment>
	);
};

export default Refunds;
