import React from "react";
import styles from "./Tabs.module.scss";
import { Link } from "react-router-dom";

type LinkTabData = {
  id: number;
  title: string;
  url: string;
  value: string;
};

type LinkTabProps = {
  tabs: LinkTabData[];
  activeTab: string;
};

export const LinkTab: React.FC<LinkTabProps> = ({ tabs, activeTab }) => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <Link
            key={index}
            className={`${styles.tab} ${
              activeTab?.toLowerCase() === tab.value?.toLowerCase()
                ? styles.active
                : ""
            }`}
            to={tab.url}
          >
            {tab.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

type ButtonTabData = {
  id: number;
  title: string;
  value: string;
};

type ButtonTabProps = {
  tabs: ButtonTabData[];
  activeTab: string;
  handleTabChange: (value: string) => void;
};

export const ButtonTab: React.FC<ButtonTabProps> = ({
  tabs,
  activeTab,
  handleTabChange,
}) => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tab} ${
              activeTab?.toLowerCase() === tab.value?.toLowerCase()
                ? styles.active
                : ""
            }`}
            onClick={() => handleTabChange(tab.value)}
          >
            {tab.title}
          </button>
        ))}
      </div>
    </div>
  );
};
