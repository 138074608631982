import React, { useState, useEffect, Fragment, useRef } from 'react';
import styles from './Steptwo.module.scss';
import  TWDIcon from '../../../../assets/images/Logos/TWD_final_logo.svg';
import OutlineTextInput from '../../../../components/input/OutlineTextInput';
import { useDispatch, useSelector } from 'react-redux';
import client from '../../../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../../constants/toastStyles';
import useApi from '../../../../hooks/useApi';
import currenciesApi from '../../../../api/currencies';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from '../../../../utils/formatNumber';
import { Collapse } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../../constants/modalStyles';
import html2canvas from 'html2canvas';
import{ ReactComponent as SuccessIcon } from '../../../../assets/images/Icons/successGreen.svg';


const { Panel } = Collapse;

const StepTwo = ({ setSteps, stepOneInputs, invoiceData }: any) => {
	const dispatch = useDispatch();
	const history = useHistory();

	interface initTypes {
		title: string;
		description: string;
		amount?: string;
	}

	console.log({ invoiceData })
	const initProps: initTypes = {
		title: invoiceData?.title ?? "",
		description: invoiceData?.description ?? "",
		amount: invoiceData?.amount ?? "",
	};

	const [inputs, setInputs] = useState(invoiceData ?? initProps);
	const [tax, setTax] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [discount, setDiscount] = useState('');

	const getCurrencies = useApi(currenciesApi.getCurrencies);
	const currencies = getCurrencies?.data?.data;

	const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
	let currencyOptions = currencies?.map(function (currency: any) {
		return {
			value: currency.id,
			label: currency.name,
			shortName: currency.shortName,
		};
	});

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	type itemProps = {
		amount: number;
		quantity: number;
		item: string;
	};

	const [items, setItems] = useState<itemProps[]>([
		{ amount: 0, quantity: 0, item: '' },
	]);

	let handleChange = (index: any, e: any) => {
		let newItems = [...items];
		if (e.target.name.startsWith('amount')) {
			newItems[index].amount = Number(e.target.value);
		} else if (e.target.name.startsWith('quantity')) {
			newItems[index].quantity = Number(e.target.value);
		} else {
			newItems[index].item = e.target.value;
		}
		setItems(newItems);
	};

	let addFormFields = () => {
		setItems([...items, { amount: 0, quantity: 0, item: '' }]);
	};

	let removeFormFields = (i: any) => {
		let newItems = [...items];
		newItems.splice(i, 1);
		setItems(newItems);
	};

	const fetchCurrencies = async () => {
		getCurrencies.request();
	};

	const totalItemsAmount = items.length < 1 ? 0 : items
		.map((item) => item.amount * item.quantity)
		.reduce((prev, next) => prev + next);

	// const totalAmount = inputs.amount ?? (
	// 	!!discount && !!tax
	// 		? totalItemsAmount -
	// 		Math.abs(parseInt(discount)) +
	// 		Math.abs(parseInt(tax))
	// 		: !discount && !!tax
	// 			? totalItemsAmount + Math.abs(parseInt(tax))
	// 			: !discount && !tax
	// 				? totalItemsAmount
	// 				: totalItemsAmount - Math.abs(parseInt(discount)));

	const totalAmount = inputs.amount ?? (
		!!discount && !!tax
			? totalItemsAmount -
			(totalItemsAmount * Math.abs(parseFloat(discount)) / 100) +
			(totalItemsAmount * Math.abs(parseFloat(tax)) / 100)
			: !discount && !!tax
				? totalItemsAmount + (totalItemsAmount * Math.abs(parseFloat(tax)) / 100)
				: !discount && !tax
					? totalItemsAmount
					: totalItemsAmount - (totalItemsAmount * Math.abs(parseFloat(discount)) / 100)
	);
					
	useEffect(() => {
		window.scrollTo(0, 0);
		fetchCurrencies();
	}, []);

	useEffect(() => {
		if (!invoiceData || !invoiceData?.title) return;
		setInputs(invoiceData)
		setItems(invoiceData?.invoiceItems ?? [{ amount: 0, quantity: 0, item: '' }])
		var curr = currencies?.find((x: any) => x.shortName == invoiceData?.currency) ?? {}
		setSelectedCurrency({
			value: curr.id,
			label: curr.name,
			shortName: curr.shortName,
		})
		setTax(invoiceData?.tax)
		setDiscount(invoiceData?.discount)
		var dd = moment(invoiceData?.dueDate).format("yyyy-MM-DD hh:mm:ss");
		setStartDate(new Date(dd))
	}, [invoiceData]);

	const payload = {
		invoiceTitle: inputs.title,
		description: inputs.description,
		amount: totalAmount,
		dueDate: startDate,
		discount: discount,
		tax: tax,
		invoiceItems: items,
		currencyId: selectedCurrency?.value,
		businessDetails: {
			customerName: stepOneInputs.customerName,
			companyName: stepOneInputs.companyName,
			emailAddress: stepOneInputs.emailAddress,
			companyEmailAddress: stepOneInputs.companyEmailAddress,
		},
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		var path = invoiceData?.id > 0 ? `/subsidiary/dashboard/invoice/detail/${invoiceData.id}/resend` : "/subsidiary/dashboard/invoice/create"
		client
			.post(path, payload)
			.then((data: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				openModalSuccess();
				if (!(invoiceData?.id > 0)) history.push('/dashboard/business/invoice');
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				if (error?.response?.data?.message === 'Please login again') {
					sessionStorage.clear();
					window.location.href = '/';
				} else {
					const message = error?.response?.data?.message;

					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				}
			});
	};

	const InvoiceRef = useRef<HTMLDivElement | null>(null);
	const [showInvoiceRef, setShowInvoiceRef] = useState(false);

	const downloadInvoice = () => {
	  setShowInvoiceRef(true); // Show the section briefly
	  setTimeout(() => {
		if (InvoiceRef.current) {
		  html2canvas(InvoiceRef.current).then((canvas) => {
			const dataUrl = canvas.toDataURL('image/png');
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = 'Invoice.png';
			link.click();
			setShowInvoiceRef(false);
		  });
		}
	  }, 0);
	};
	

	  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false); // Modal state
	  const [successMessage, setSuccessMessage] = useState(''); // Success message
  
	  const openModalSuccess = () => setModalIsOpenSuccess(true);
	  const closeModalSuccess = () => setModalIsOpenSuccess(false);
  
  
	  
	return (

		<>
			<div className={styles.business}>
				<span style={{width:'100%', textAlign:'start', padding:'0px 24px'}}>	<p style={{fontWeight:'600', fontSize:'large', textAlign:'start'}}>Invoice Details</p></span>
				{/* Form Fields */}
				<div className={styles.businessCard} style={{ marginBottom: '30px' }}>
					<div className={styles.businessForm}>
						<form className={styles.form}>
							{/* Group of 3 Fields */}
							<div className={styles.rowContainer}>
								{/* Invoice Title Field */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='invoice_title'
										inputLabel='Invoice Title'
										inputValue={inputs.invoice_title}
										style={{ height: '40px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Invoice Title</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '5px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.invoice_title || 'Invoice Title'}
										</span>
									</div>
								)}

								{/* Customer Name Field */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='customerName'
										inputLabel='Customer Name'
										inputValue={inputs.customerName}
										style={{ height: '40px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Customer Name</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.customerName || 'Customer Name'}
										</span>
									</div>
								)}

								{/* Customer Email Field */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='emailAddress'
										inputLabel='Customer Email'
										inputValue={inputs.emailAddress}
										style={{ height: '40px', marginLeft: '15px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Customer Email</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.emailAddress || 'Customer Email'}
										</span>
									</div>
								)}
							</div>

							{/* Group of 3 Fields */}
							<div className={styles.rowContainer}>
								{/* Company Email */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='companyEmailAddress'
										inputLabel='Company Email'
										inputValue={inputs.companyEmailAddress}
										style={{ height: '40px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Company Email</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.companyEmailAddress || 'Company Email'}
										</span>
									</div>
								)}

								{/* Company Name */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='companyName'
										inputLabel='Company Name'
										inputValue={inputs.companyName}
										style={{ height: '40px', marginLeft: '15px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Company Name</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.companyName || 'Company Name'}
										</span>
									</div>
								)}

								{/* Date Field */}
								{!invoiceData?.id ? (
									<div
										className={styles.inputflex}
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											marginLeft: '15px',
											marginRight: '15px',
											position: 'relative',
										}}
									>
										<div style={{ width: '100%' }}>
											<DatePicker
												selected={startDate}
												onChange={(date: Date) => setStartDate(date)}
												placeholderText="Select due date"
												wrapperClassName={styles.datePickerWrapper}
												className={styles.datePickerInput}
												dateFormat="dd/MM/yyyy"
											/>
										</div>
									</div>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Select due date</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{startDate ? startDate.toLocaleDateString() : 'Select due date'}
										</span>
									</div>
								)}
							</div>

							{/* Tax, Discount, and Currency */}
							<div className={styles.rowContainer}>
								{/* Tax */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='tax'
										inputLabel='Add Tax'
										inputValue={inputs.tax}
										style={{ height: '40px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Add Tax</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.tax || '0%'}
										</span>
									</div>
								)}

								{/* Discount */}
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='discount'
										inputLabel='Add Discount'
										inputValue={inputs.discount}
										style={{ height: '40px', marginRight: '15px' }}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Add Discount</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.discount || '0%'}
										</span>
									</div>
								)}

								{/* Currency */}
								{!invoiceData?.id ? (
									<Select
										defaultValue={selectedCurrency}
										onChange={setSelectedCurrency}
										options={currencyOptions}
										placeholder="Currency"
										styles={{
											control: (provided) => ({
												...provided,
												height: '55px',
												minHeight: '55px',
												borderRadius: '10px',
												boxShadow: 'none',
											}),
											menu: (provided) => ({
												...provided,
												zIndex: 9999,
											}),
										}}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Currency</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '55px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{selectedCurrency?.label || 'Currency'}
										</span>
									</div>
								)}
							</div>
						</form>
					</div>
				</div>

				{/* Field to add new items */}
				<div className={styles.businessCard} style={{ marginBottom: '30px' }}>
					<div className={styles.tableContainer}>
						<table className={styles.itemTable}>
							<thead>
								<tr>
									<th>Title</th>
									<th>Quantity</th>
									<th>Rate</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{items.map((element, index) => (
									<tr key={index}>
										<td>
											{!invoiceData?.id ? (
												<input
													type='text'
													value={element.item || ''}
													onChange={(e) => handleChange(index, e)}
												/>
											) : (
												<span>{element.item || 'Item Name'}</span>
											)}
										</td>
										<td>
											{!invoiceData?.id ? (
												<input
													type='number'
													value={element.quantity || ''}
													onChange={(e) => handleChange(index, e)}
												/>
											) : (
												<span>{element.quantity || '0'}</span>
											)}
										</td>
										<td>
											{!invoiceData?.id ? (
												<input
													type='number'
													value={element.amount || ''}
													onChange={(e) => handleChange(index, e)}
												/>
											) : (
												<span>{element.amount || '0'}</span>
											)}
										</td>
										<td>{(element.amount * element.quantity).toFixed(2) || '0.00'}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				{/* Field for description and total items */}
				<div className={styles.businessCard} style={{marginTop: '30px' }}>
					<div className={styles.businessForm}>
						<div className={styles.notesAndTotal}>
							{/* Left Column - Notes */}
							<div className={styles.notesSection}>
								<form className={styles.form}>
								{!invoiceData?.id ? (
									<OutlineTextInput
										handleChange={updateProps}
										inputName='description'
										inputLabel='Note to recipient'
										inputValue={inputs.description}
										style={{ height: '100%', width:'100%', background:'transparent' }} 
										multiline= {true}
									/>
								) : (
									<div>
										<label style={{ fontSize: '12px', color: '#888' }}>Note To Recipient</label>
										<span
											style={{
												display: 'block',
												border: '1px solid #ccc',
												padding: '8px 12px',
												borderRadius: '10px',
												background: '#f9f9f9',
												height: '110px',
												lineHeight: '36px',
												overflow:'scroll'
												
											}}
										>
											{inputs.description || 'Note To Recipient'}
										</span>
									</div>
								)}
								</form>
							</div>

							{/* Right Column - Total Breakdown */}
							<div className={styles.totalSection}>
								<div className={styles.subTotal}>
									<div className={styles.text}>Subtotal:</div>
									<div className={styles.amount}>
										{selectedCurrency?.shortName}{' '}
										{!!totalItemsAmount ? numberWithCommas(totalItemsAmount) : '0.00'}
									</div>
								</div>
								<div className={styles.taxTotal}>
									<div className={styles.text}>Tax (10%):</div>
									<div className={styles.amount}>
										{selectedCurrency?.shortName}{' '}
										{!!tax ? numberWithCommas(parseInt(tax)) : '0.00'}
									</div>
								</div>
								<hr className={styles.totalDivider} />
								<div className={styles.grandTotal}>
									<div className={styles.text}>Total:</div>
									<div className={styles.amount}>
										{selectedCurrency?.shortName}{' '}
										<strong>{!!totalAmount ? numberWithCommas(totalAmount) : '0.00'}</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.footButtons}>
					<div
						className={styles.backButton}
						onClick={() => {
							setSteps('one');
						}}>
						Back
					</div>
					<div
						className={styles.nextButton}
						onClick={(e) => {
							handleSubmit(e);
						}}>
						Send invoice
					</div>
				</div>

				{/* Success Modal */}
				<Modal
					isOpen={modalIsOpenSuccess}
					// isOpen={true}
					onRequestClose={closeModalSuccess}
					contentLabel="Success Modal"
					style={customStyles}
				>
					<div className={styles.modalBody}>
						<div className={styles.modalHeader}>
							<SuccessIcon style={{ height: '90px', width: '90px' }} />
						</div>
						<div className={styles.modalContent}>
							<div className={styles.confirmation}>Invoice sent Successfully</div>
						</div>
						<div className={styles.modalFooter}>
							<div className={styles.cancel} onClick={closeModalSuccess}>
								Close
							</div>
							<div className={styles.cancel} onClick={() => (downloadInvoice(), closeModalSuccess())}>
								Download Invoice
							</div>
						</div>
					</div>
				</Modal>
			</div>
			{/* Interface to be downloaded */}
			{showInvoiceRef && (
				<div ref={InvoiceRef} className={styles.downloadInvoiceinvoiceContainer}>
					<div className={styles.downloadInvoiceheader}>
						<div>
							<div className={styles.logo}>
								<img src="/path-to-logo" alt="Logo" className={styles.downloadInvoicelogoImage} />
							</div>
							<div className={styles.downloadInvoicemerchantInfo}>
								<h1>{inputs.companyName }</h1>
							</div>
						</div>
						<div className={styles.downloadInvoiceinvoiceDetails}>
							<p>INVOICE</p>
							<p>#001</p>
						</div>
					</div>

					<div className={styles.downloadInvoicebillTo}>
						<div>
							<h5 style={{color:'black', fontWeight:'bold'}}>Bill to</h5>
							<p>{inputs.customerName }</p>
							<p>{inputs.emailAddress}</p>
							<p>{inputs.companyName}</p>
						</div>
						<div>
						<p> <strong style={{color:'black', fontWeight:'bold'}}> Invoice date:</strong> { startDate.toLocaleDateString()} </p>
						<p> <strong style={{color:'black', fontWeight:'bold'}}> Due date:</strong> {totalAmount} </p>
						<p style={{background:'#8E183E4D', padding:'10px'}}> <strong style={{color:'black', fontWeight:'bold'}}> Balance due:</strong> {totalAmount} </p>
						</div>
					</div>

					<div className={styles.downloadInvoiceitems}>
						<table>
						<thead>
							<tr>
							<th>Title</th>
							<th>Quantity</th>
							<th>Rate</th>
							<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{items.map((item, index) => (
							<tr key={index}>
								<td>{item.item}</td>
								<td>{item.quantity}</td>
								<td>{item.amount}</td>
								<td>{(item.amount * item.quantity).toFixed(2)}</td>
							</tr>
							))}
						</tbody>
						</table>
					</div>

					<div className={styles.downloadInvoicesummary}>
						<p><strong>Subtotal: </strong>{inputs.subTotal}</p>
						<p><strong>Tax: </strong>{tax}</p>
						<p><strong>Discount: </strong>{discount}</p>
						<p><strong>Total: </strong>{totalAmount}</p>
					</div>

					<div className={styles.downloadInvoicefooter}>
						<p style={{marginBottom:'2px'}}>Powered by</p>
						<img src={TWDIcon} style={{verticalAlign: "middle", objectFit:'contain',marginLeft: "5px"}} alt="Transactpay Logo"  />
					</div>
				</div>
			)}
		</>

	);
};

export default StepTwo;
