import React, { Fragment, useState } from 'react';
import styles from './Newinvoice.module.scss';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Line from '../../../assets/IconsMerchant/line.svg';
import { Divider } from '@mui/material';

import StepTwo from './StepTwo/StepTwo';

const NewInvoice = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	interface initTypes {
		customerName: string;
		companyName: string;
		emailAddress: string;
		companyEmailAddress: string;
	}

	const initProps: initTypes = {
		customerName: '',
		companyName: '',
		emailAddress: '',
		companyEmailAddress: '',
	};

	const [inputs, setInputs] = useState(initProps);
	const [step, setSteps] = useState('one');

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const disableFunction = () => {
		if (
			inputs.customerName === '' ||
			inputs.companyName === '' ||
			inputs.emailAddress === '' ||
			inputs.companyEmailAddress === ''
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className={styles.business}>
			<div className={styles.topSections}>
				<div style={{color:'#8E183E'}} className={styles.newInvoice}>Invoices</div>
			</div>

			<StepTwo setSteps={setSteps} stepOneInputs={inputs} />
		</div>
		// <div className={styles.business}>
		// 	<div className={styles.topSections}>
		// 		<div className={styles.newInvoice}>New Invoice</div>
		// 		<div className={styles.stepwrapper}>
		// 			<div className={styles.stepper}>
		// 				<div
		// 					className={
		// 						step === 'one' ? styles.circleActive : styles.circle
		// 					}></div>
		// 				<div>
		// 					<p className={styles.stepper_p}>Business Details</p>
		// 				</div>
		// 			</div>
		// 			<div
		// 				style={{
		// 					margin: '5px 6px',
		// 				}}>
		// 				<img src={Line} alt='' />
		// 			</div>

		// 			<div className={styles.stepper}>
		// 				<div
		// 					className={
		// 						step === 'two' ? styles.circleActive : styles.circle
		// 					}></div>
		// 				<div>
		// 					<p className={styles.stepper_p}>Invoice Details</p>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// 	{step === 'one' && (
		// 		<div className={styles.businessCard} style={{ marginTop: '30px' }}>
		// 			<div className={styles.logoText}>Customers details</div>

		// 			<div className={styles.businessForm}>
		// 				<form className={styles.form}>
		// 					<div
		// 						className={styles.inputflex}
		// 						style={{ display: 'flex', width: '100%' }}>
		// 						<OutlineTextInput
		// 							style={{ marginRight: '33px', flex: '1' }}
		// 							handleChange={updateProps}
		// 							inputName='customerName'
		// 							inputLabel='Customer name'
		// 							inputValue={inputs.customerName}
		// 						/>
		// 						<OutlineTextInput
		// 							style={{ flex: '1' }}
		// 							handleChange={updateProps}
		// 							inputName='companyName'
		// 							inputLabel='Company name'
		// 							inputValue={inputs.companyName}
		// 						/>
		// 					</div>

		// 					<div
		// 						className={styles.inputflex}
		// 						style={{ display: 'flex', marginBottom: '20px' }}>
		// 						<OutlineTextInput
		// 							style={{ marginRight: '33px', flex: '1' }}
		// 							handleChange={updateProps}
		// 							inputName='emailAddress'
		// 							inputLabel='Customer email'
		// 							inputValue={inputs.emailAddress}
		// 						/>
		// 						<OutlineTextInput
		// 							style={{ flex: '1' }}
		// 							handleChange={updateProps}
		// 							inputName='companyEmailAddress'
		// 							inputLabel='Company email'
		// 							inputValue={inputs.companyEmailAddress}
		// 						/>
		// 					</div>
		// 				</form>
		// 			</div>
		// 			<Divider />
		// 			<div className={styles.footButtons}>
		// 				<div
		// 					className={styles.backButton}
		// 					onClick={() => {
		// 						history.goBack();
		// 					}}>
		// 					Back
		// 				</div>
		// 				{disableFunction() ? (
		// 					<div className={styles.nextButtonDisabled}>Next</div>
		// 				) : (
		// 					<div
		// 						className={styles.nextButton}
		// 						onClick={() => {
		// 							setSteps('two');
		// 						}}>
		// 						Next
		// 					</div>
		// 				)}
		// 			</div>
		// 		</div>
		// 	)}
		// 	{step === 'two' && <StepTwo setSteps={setSteps} stepOneInputs={inputs} />}
		// </div>
	);
};

export default NewInvoice;
