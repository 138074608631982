import React, { useState, useEffect, useRef } from 'react';
import styles from './Business.module.scss';
import ImagePlaceholder from '../../assets/images/Icons/image-placeholder.png';
import { ReactComponent as UploadIcon } from '../../assets/images/Icons/upload-image.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/Icons/save.svg';
import { ReactComponent as ResetIcon } from '../../assets/images/Icons/reset-password.svg';
import { ReactComponent as LoopIcon } from '../../assets/IconsMerchant/rotate.svg';
import { ReactComponent as HighlightOffIcon } from '../../assets/IconsMerchant/imageclose.svg';

import OutlineTextInput from '../../components/input/OutlineTextInput';
import { useSelector, useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import client, { formClient } from '../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { Divider } from 'antd';
import StatusView from '../../components/StatusView/StatusView';

const Business = () => {
	const dispatch = useDispatch();
	const ref = React.useRef<HTMLInputElement>(null);

	const [defaultSub, setDefaultSub] = useState<any>();
	// const { subsidiaryInfo } = useSelector((state) => state.subsidiaryInfoReducer);
	const { activeSubsidiary } = useSelector(
		(state) => state.subsidiaryInfoReducer
	);
	// const subsidiaryDetails = subsidiaryInfo?.data?.subsidiaryDetails;
	// const getMe = async () => {
	// 	try {
	// 		const data: any = await client.get('/subsidiary/dashboard/me');
	// 		setDefaultSub(data?.data);
	// 		setInputs(data?.data?.subsidiaryDetails?.subsidiaries[0]);
	// 		// dispatch(saveSubsidiaryInfo(data?.data));
	// 	} catch (error: any) {
	// 		if (error?.response?.data?.message === 'Please login again') {
	// 			sessionStorage.clear();
	// 			window.location.href = '/';
	// 		} else {
	// 			const message = error?.response?.data?.message;

	// 			dispatch(
	// 				openToastAndSetContent({
	// 					toastContent: message,
	// 					toastStyles: ToastErrorStyles,
	// 				})
	// 			);
	// 		}
	// 	}
	// };
	interface initTypes {
		businessName: string;
		businessType: string;
		supportEmail: string;
		description: string;
		legalBusinessName: string;
		name: string;
		newPassword: string;
		oldPassword: string;
		phone: string;
		avatar: any;
	}

	const initProps: initTypes = {
		businessName:
			activeSubsidiary?.businessName,
		businessType:
			activeSubsidiary?.businessType,
		supportEmail:
			activeSubsidiary?.supportEmail,
		description: activeSubsidiary?.description,
		phone: activeSubsidiary?.mobileNumber,
		legalBusinessName:
			activeSubsidiary?.legalBusinessName,
		name: activeSubsidiary?.name,
		newPassword: '',
		avatar: '',
		oldPassword: '',
	};

	const [inputs, setInputs] = React.useState(initProps);

	useEffect(() => {
		// getMe();
	}, []);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const updateImageProps = async (event: any) => {
		dispatch(openLoader());
		let formData = new FormData();
		formData.append('logo', event?.target?.files[0]);
		try {
			const data: any = await formClient.post(
				'/subsidiary/dashboard/business/logos/add-or-update',
				formData
			);

			const message = data?.data?.message;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
			window.location.reload();
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const updateBusinessPayload = {
		businessName: inputs?.businessName,
		businessEmail: inputs?.supportEmail,
		description: inputs?.description,
		businessType: inputs?.businessType,
		legalBusinessName: inputs?.legalBusinessName,
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('/subsidiary/dashboard/business/update', updateBusinessPayload)
			.then((data: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const handleAvatarRemove = async () => {
		dispatch(openLoader());
		try {
			const data: any = await formClient.post(
				'/subsidiary/dashboard/business/logos/add-or-update',
				''
			);

			const message = data?.data?.message;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const changePassword = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post(
				'/subsidiary/dashboard/change-password',
				{
					password: inputs?.oldPassword,
					newPassword: inputs?.newPassword,
				}
			);

			const message = data?.data?.message;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	// console.log({ activeSubsidiary })
	return (
		<div className={styles.business}>
			<div className={styles.info_wrapper}>
				<div className={styles.info_word}>
					<div className={styles.business_status}>
						<h2>Business Information</h2>
						<StatusView
							status={activeSubsidiary.status}
							orange='Pending-Approval'
							green='Active'
							indigo='New'
						/>
					</div>
					<p>Enter your business information and ensure they are correct</p>
				</div>
				<div className={styles.info_data}>
					<div className={styles.user_description}>
						<div className={styles.user_description_image}>
							{activeSubsidiary?.subsidiaryLogo ? (
								<img
									className={styles.user_description_image_main}
									src={activeSubsidiary?.subsidiaryLogo}
									alt=''
								/>
							) : (
								<img
									className={styles.user_description_image_main}
									src='https://i.ibb.co/fH4x0Xk/360-F-346936114-Rax-E6-OQogebg-AWTal-E1myse-Y1-Hbb5q-PM.jpg'
									alt=''
								/>
							)}
						</div>

						<div className={styles.user_description_action}>
							<div className={styles.user_description_action_item}>
								<div className={styles.icon_box}>
									<LoopIcon />
								</div>
								<div className={styles.actions}>
									<form>
										<label
											style={{ cursor: 'pointer' }}
											onClick={() => ref?.current?.click()}>
											<h4 className={styles.actions_h4}>Update</h4>
										</label>
										<input
											type='file'
											name='avatar'
											id='file'
											accept='image/png,image/jpg,image/jpeg'
											onChange={updateImageProps}
											ref={ref}
											hidden
										/>
									</form>
								</div>
							</div>
							<div className={styles.line}></div>
							<div
								onClick={handleAvatarRemove}
								className={styles.user_description_action_item}>
								<div className='icon_box'>
									<HighlightOffIcon />
								</div>
								<h4 className={styles.actions_h4}>Remove</h4>
							</div>
						</div>
					</div>
					<div className={styles.businessCard} style={{ marginTop: '35px' }}>
						<div className={styles.businessForm}>
							<form className={styles.form}>
								<OutlineTextInput
									handleChange={updateProps}
									inputName='businessName'
									inputLabel='Business name'
									inputValue={inputs?.businessName}
								/>
								<OutlineTextInput
									handleChange={updateProps}
									inputName='supportEmail'
									inputLabel='Business email'
									inputValue={inputs?.supportEmail}
								/>

								<OutlineTextInput
									inputName='businessType'
									inputLabel='Business type'
									inputValue={inputs?.businessType}
									inputSelect
									inputOption={['Individual', 'Company']}
								/>

								<OutlineTextInput
									handleChange={updateProps}
									inputName='legalBusinessName'
									inputLabel='Legal business name'
									inputValue={inputs?.legalBusinessName}
								/>
								<OutlineTextInput
									handleChange={updateProps}
									inputName='description'
									inputLabel='Description'
									multiline
									inputValue={inputs?.description}
								/>
							</form>

							<Divider style={{ marginTop: '64px' }} />
							{/* <div className={styles.buttonSubmit}>
								<div className={styles.uploadButton} onClick={handleSubmit}>
									Cancel
								</div>
								<div className={styles.uploadButton} onClick={handleSubmit}>
									Save Changes
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<div style={{ marginTop: '20px' }} className={styles.info_wrapper}>
				<div className={styles.info_word}>
					<h2>Password</h2>
					<p>Enter your personal information and ensure they are correct</p>
				</div>
				<div className={styles.info_data}>
					<div className={styles.passwordForm}>
						<form>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='oldPassword'
								inputLabel='Old Password'
								inputType='Password'
								inputValue={inputs.oldPassword}
							/>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='newPassword'
								inputLabel='New Password'
								inputType='Password'
								inputValue={inputs.newPassword}
							/>
						</form>
						<div style={{ marginTop: '20px' }} className={styles.buttonSubmit}>
							<div className={styles.uploadButton} onClick={changePassword}>
								Reset password
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Business;
