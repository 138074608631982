import React, { useEffect } from 'react';
import styles from './Addnewsub.module.scss';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client, { formClient } from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { ReactComponent as UploadIcon } from '../../../assets/images/Icons/upload-image.svg';
import ImagePlaceholder from '../../../assets/images/Icons/image-placeholder.png';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
	phoneInputStyle,
} from '../../../constants/toastStyles';
import useApi from '../../../hooks/useApi';
import countriesApi from '../../../api/countries';
import meApi from '../../../api/me';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ReactComponent as InfoIcon } from '../../../assets/images/Icons/info.svg';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useHistory } from 'react-router-dom';

const AddNewSub = ({ closeModal }: any) => {
	const getCountriesApi = useApi(countriesApi.getCountries);
	const getMeApi = useApi(meApi.getMe);
	const history = useHistory();
	const fetchCountries = async () => {
		getCountriesApi.request();
	};

	const fetchMe = async () => {
		getMeApi.request();
	};

	const meBusinessId =
		getMeApi?.data?.subsidiary_details?.subsidiaries[0]?.business_id;

	const [selectedValue, setSelectedValue] = React.useState('');
	const [selectedCountry, setSelectedCountry] = React.useState<any>();

	let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
		selectedCountry: any
	) {
		return {
			value: selectedCountry.shortName,
			label: selectedCountry.name,
			currency: selectedCountry.countryCode,
		};
	});
	const selectedCountryShort = selectedCountry?.value;

	const dispatch = useDispatch();
	interface initTypes {
		nameOfSubsidiary: string;
		description: string;
		country: string;
		email: string;
		avatar: any;
		icon: string;
		Individual: string;
		Company: string;
		isDefault: string;
	}

	const initProps: initTypes = {
		nameOfSubsidiary: '',
		description: '',
		country: '',
		email: '',
		avatar: '',
		icon: '',
		Individual: 'Individual',
		Company: 'Company',
		isDefault: 'false',
	};
	const [inputs, setInputs] = React.useState(initProps);
	console.log({ selectedCountry })
	const [phoneNumber, setPhoneNumber] = React.useState(
		selectedCountry?.currency
	);

	const disableFunction = () => {
		if (
			!!inputs.nameOfSubsidiary ||
			!!inputs.country ||
			!!selectedValue ||
			!!selectedCountry ||
			!!inputs.description ||
			!!inputs.email ||
			!!inputs.avatar ||
			!!inputs.icon
		) {
			return true;
		}
	};

	// console.log('avatar', inputs.avatar);
	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	let formData = new FormData();

	formData.append('nameOfSubsidiary', inputs.nameOfSubsidiary);
	formData.append('description', inputs.description);
	formData.append('country', selectedCountryShort);
	formData.append('email', inputs.email);
	formData.append('image', inputs.avatar);
	formData.append('businessType', selectedValue);
	// formData.append('businessId', meBusinessId);
	formData.append('isDefault', inputs.isDefault);
	formData.append(
		'mobileNumber',
		`${selectedCountry?.currency}${phoneNumber?.replace(/\s/g, ' ').trim()}`
	);

	const createPaymentLink = async () => {
		dispatch(openLoader());
		try {
			const data: any = await formClient.post(
				'/subsidiary/dashboard/create-subsidiary',
				formData
			);

			const message = data?.data?.message;
			dispatch(closeLoader());
			closeModal();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
			history.push("/dashboard")
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		createPaymentLink();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(event.target.value);
	};
	const updateImageProps = (event: any) => {
		const newValue = event?.target?.files[0];
		const inputName = event?.target?.name;
		setInputs((prevState: any) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: '#016E20',
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#016E20',
			fontFamily: "'Kumbh Sans'",
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '20px',
			maxWidth: 260,
			padding: '8px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			letterSpacing: '-0.006em',
			color: '#FFFFFF',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
			borderRadius: '4px',
		},
	}));

	useEffect(() => {
		fetchCountries();
		fetchMe();
	}, []);
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Add New Merchant</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='nameOfSubsidiary'
						inputLabel='Business Name'
						inputValue={inputs.nameOfSubsidiary}
					/>

					<div>
						<label className={styles.inputLabel}>Country</label>
						<Select
							defaultValue={selectedCountry}
							onChange={setSelectedCountry}
							options={selectedCountryOptions}
							// styles={customStyles}
							className={styles.select}
							styles={{
								// Fixes the overlapping problem of the component
								menu: (provided) => ({
									...provided,
									zIndex: 9999,
									width: '100%',
								}),
							}}
						/>
					</div>

					<div>
						<label className={styles.inputLabel}>Phone number</label>
						<div className={styles.phoneInput}>
							<input
								type='text'
								disabled
								value={selectedCountry?.currency}
								style={{
									width: '20%',
									fontFamily: 'Kumbh Sans',
									fontStyle: 'normal',
									fontWeight: 500,
									fontSize: '16px',
									lineHeight: '24px',
									padding: '1rem',
									borderRadius: '10px 0px 0px 10px',
									border: '1px solid #616161',
								}}
							/>
							<input
								type='number'
								name='phoneNumber'
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								style={phoneInputStyle}
								className='no-arrow'
							/>
						</div>
					</div>

					<div className={styles.profileCard}>
						<div className={styles.logoText}>Business Logo</div>

						<div className={styles.imagePlaceholder}>
							<img
								src={
									inputs?.avatar
										? URL.createObjectURL(inputs?.avatar)
										: ImagePlaceholder
								}
								alt='logo-placeholder'
								width={100}
								height={100}
								style={{ borderRadius: '8px' }}
							/>
						</div>
						<div className={styles.uploadButton}>
							<UploadIcon />

							<input type='file' name='avatar' onChange={updateImageProps} />
						</div>
					</div>

					{/* <div className={styles.profileCard}>
            <div className={styles.logoText}>Business icon</div>
            <div className={styles.recommend}>
              We recommend an image of at least 400x400
            </div>
            <div className={styles.imagePlaceholder}>
              <img
                src={inputs?.icon ? inputs?.icon : ImagePlaceholder}
                alt="logo-placeholder"
                width={100}
                height={100}
                style={{ borderRadius: "8px" }}
              />
            </div>
            <div className={styles.uploadButton}>
              <UploadIcon />

              <input type="file" name="icon" onChange={updateImageProps} />
            </div>
          </div> */}
					<OutlineTextInput
						handleChange={updateProps}
						inputName='description'
						inputLabel='Description'
						inputValue={inputs.description}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='email'
						inputLabel='Business Email'
						inputValue={inputs.email}
					/>

					<div className={styles.businessType}>Business type</div>
					<div className={styles.formSelection}>
						<div className={styles.radioSelection}>
							<Radio
								checked={selectedValue === inputs.Individual}
								onChange={handleChange}
								value={inputs.Individual}
								name='Individual'
								inputProps={{ 'aria-label': inputs.Individual }}
								size='small'
							/>
							<div className={styles.radioLabel}>
								Individual{' '}
								<LightTooltip
									title="Description of what business type 'individual' is"
									placement='top'
									arrow>
									<IconButton>
										<InfoIcon className={styles.infoIcon} />
									</IconButton>
								</LightTooltip>
							</div>
						</div>
						<div className={styles.radioSelection}>
							<Radio
								checked={selectedValue === inputs.Company}
								onChange={handleChange}
								value={inputs.Company}
								name='Company'
								inputProps={{ 'aria-label': inputs.Company }}
								size='small'
							/>
							<div className={styles.radioLabel}>
								Company{' '}
								<LightTooltip
									title="Description of what business type 'company' is"
									placement='top'
									arrow>
									<IconButton>
										<InfoIcon className={styles.infoIcon} />
									</IconButton>
								</LightTooltip>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						Create
					</div>
				) : (
					<div className={styles.notClickable}>Create </div>
				)}
			</div>
		</div>
	);
};

export default AddNewSub;
