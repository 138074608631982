import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CreateCategoryComponent = lazy(() => import("./CreateCategory"));

const CreateCategoryPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CreateCategoryComponent />
  </Suspense>
);

export default CreateCategoryPage;
