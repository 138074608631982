import React from "react";

export interface QrCodesData {
  name: string;
  QrCodeType: string;
//   limit: string;
//   amount: string;
  isActive: React.ReactNode;
  action: React.ReactNode;
//   currency: string;
}

export const ColumnQrCodes = [
  {
    Header: "QR Code Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "QrCodeType",
  },
  {
    Header: "Status",
    accessor: "isActive",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
