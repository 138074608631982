import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogEditCategoryComponent = lazy(() => import("./CatalogEditCategory"));

const CatalogEditCategoryPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CatalogEditCategoryComponent />
  </Suspense>
);

export default CatalogEditCategoryPage;
