import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogCategoriesComponent = lazy(() => import("./CatalogCategories"));

const CatalogCategoriesPage = () => (
    <Suspense fallback={<LoaderPage />}>
        <CatalogCategoriesComponent />
    </Suspense>
)

export default CatalogCategoriesPage;