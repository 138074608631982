import React, { Fragment, useEffect, useState } from 'react';
import styles from './InvoiceDetails.module.scss';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Line from '../../../assets/IconsMerchant/line.svg';
import { Divider } from '@mui/material';
import client from "../../../api/client";
import StepTwo from './StepTwo/StepTwo';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { ToastErrorStyles } from '../../../constants/toastStyles';

const InvoiceDetails = () => {
	const dispatch = useDispatch();
	const params: any = useParams();
	const invoiceId = params.id
	console.log({ params, invoiceId })

	const history = useHistory();
	interface initTypes {
		customerName: string;
		companyName: string;
		emailAddress: string;
		companyEmailAddress: string;
	}

	const initProps: initTypes = {
		customerName: '',
		companyName: '',
		emailAddress: '',
		companyEmailAddress: '',
	};

	const [inputs, setInputs] = useState<any>(initProps);
	const [step, setSteps] = useState('one');

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const disableFunction = () => {
		if (
			inputs.customerName === '' ||
			inputs.companyName === '' ||
			inputs.emailAddress === '' ||
			inputs.companyEmailAddress === ''
		) {
			return true;
		} else {
			return false;
		}
	};

	const fetchInvoiceDetails = async () => {
		dispatch(openLoader());
		try {
			const { data }: any = await client.get(
				`/subsidiary/dashboard/invoice/detail/${invoiceId}`
			);
			const invoice = data?.data ?? {};
			invoice.companyEmailAddress = invoice.companyEmail;
			invoice.emailAddress = invoice.customerEmail;
			setInputs(invoice);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === "Please login again") {
				sessionStorage.clear();
				window.location.href = "/";
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};
	useEffect(() => {
		fetchInvoiceDetails();
	}, [invoiceId]);


	return (
		<div className={styles.business}>
			<div className={styles.topSections}>
				<div style={{color:'#8E183E'}} className={styles.newInvoice}>Invoices</div>
			</div>
			{/* <div className={styles.businessCard} style={{ marginTop: '30px' }}>
				<div className={styles.logoText}>Customers details</div>
				<div className={styles.businessForm}>
					<form className={styles.form}>
						<div
							className={styles.inputflex}
							style={{ display: 'flex', width: '100%' }}>
							<OutlineTextInput
								style={{ marginRight: '33px', flex: '1' }}
								handleChange={updateProps}
								inputName='customerName'
								inputLabel='Customer name'
								inputValue={inputs.customerName}
							/>
							<OutlineTextInput
								style={{ flex: '1' }}
								handleChange={updateProps}
								inputName='companyName'
								inputLabel='Company name'
								inputValue={inputs.companyName}
							/>
						</div>

						<div
							className={styles.inputflex}
							style={{ display: 'flex', marginBottom: '20px' }}>
							<OutlineTextInput
								style={{ marginRight: '33px', flex: '1' }}
								handleChange={updateProps}
								inputName='emailAddress'
								inputLabel='Customer email'
								inputValue={inputs.emailAddress}
							/>
							<OutlineTextInput
								style={{ flex: '1' }}
								handleChange={updateProps}
								inputName='companyEmailAddress'
								inputLabel='Company email'
								inputValue={inputs.companyEmailAddress}
							/>
						</div>
					</form>
				</div>
			</div> */}
			<div className={styles.businessCard}>
				<StepTwo invoiceData={inputs} setSteps={setSteps} stepOneInputs={inputs} />
			</div>
		</div>
	);
};

export default InvoiceDetails;
