import { useEffect, useState, useLayoutEffect } from "react";
import { Layout, Drawer } from "antd";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import styles from "./DashboardRoutes.module.scss";
import Dashboard from "../views/Dashboard/Dashboard";
import Transactions from "../views/Transactions/Transactions";
import Compliance from "../views/Compliance/Compliance";
import PaymentLinks from "../views/PaymentLinks/PaymentLinks";
import Customers from "../views/Customers/Customers";
import Disputes from "../views/Disputes/Disputes";
import Products from "../views/Products/Products";
import Refunds from "../views/Refunds/Refunds";
import Balances from "../views/Balances/Balances";
import VirtualWallets from "../views/VirtualWallets/VirtualWallets";
import Settlements from "../views/Settlements/Settlements";
import Payouts from "../views/Payouts/Payouts";
import AuditTrails from "../views/AuditTrails/AuditTrails";
import Business from "../views/Business/Business";
import ComplianceInformation from "../views/ComplianceInfo/ComplianceInfo";
import PayoutAccounts from "../views/PayoutAccounts/PayoutAccounts";
import Roles from "../views/Roles/Roles";
import Preferences from "../views/Preferences/Preferences";
import Webhooks from "../views/Webhooks/Webhooks";
import Subsidiary from "../views/Subsidiary/Subsidiary";
import CustomerDetails from "../views/CustomerDetails/CustomerDetails";
import TransactionDetails from "../views/TransactionDetails/TransactionDetails";
import BalanceHistory from "../views/BalanceHistory/BalanceHistory";
import Invoice from "../views/Invoice/Invoice";
import NewInvoice from "../views/Invoice/NewInvoice/NewInvoice";
import NewProduct from "../views/Products/NewProduct/NewProduct";
import { NavBarHeading } from "../components/navbarheading";
import { SideDrawer } from "../components/sideDrawer";
import GetStarted from "../views/GetStarteds/GetStarted";
import Onboarding from "../views/Onboardings/onboardinglist/Onboarding";
import Terms from "../views/Onboardings/terms/Terms";
import Tour from "../views/Onboardings/tour/Tour";
import BankDetails from "../views/Onboardings/bankDetails/BankDetails";
import ContactInformation from "../views/Onboardings/contactInformation/ContactInformation";
import PersonalInformation from "../views/Onboardings/personalInformation/PersonalInformation";
import BusinessInformation from "../views/Onboardings/businessInformation/BusinessInformation";
import BusinessRegistration from "../views/Onboardings/businessRegistration/BusinessRegistration";
import RollingReserve from "../views/RollingReserves/RollingReserve";
import Transfer from "../views/Transfer/Transfer";
import PendingApprovals from "../views/PendingApprovals/PendingApprovals";
import Beneficiary from "../views/beneficiary/Beneficiary";
import Fundinghistory from "../views/funding/Fundinghistory";
import Refund from "../views/Refund/Refund";
import RefundDetails from "../views/RefundsDetails/RefundDetails";
import Chargeback from "../views/Chargeback/Chargeback";
import Won from "../views/Chargeback/Won/Won";
import Lost from "../views/Chargeback/Lost/Lost";
import Awaitingresponse from "../views/Chargeback/Awaitingresponse/Awaitingresponse";
import Assessment from "../views/Chargeback/Assessment/Assessment";
import Pending from "../views/Chargeback/Pending/Pending";
// import ScrollToTop from "../ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import { saveOnboardLevelInfo } from "../redux/actions/onboardlevel/onboardLevelAction";
import client from "../api/client";
import { closeLoader, openLoader } from "../redux/actions/loader/loaderActions";
import User from "../views/User/User";
import ChargebackDetails from "../views/Chargeback/ChargebackDetails/ChargebackDetails";
import SettlementGrouped from "../views/Settlements/SettlementGrouped";
import Loader from "../components/Loader";
import InvoiceDetails from "../views/Invoice/InvoiceDetails/InvoiceDetails";
import QrCodes from "../views/QrCodes/QrCodes";
// import { getOnboardLevelMe } from '../utils/onboardLevel';
// Catalogue
import { CreateCatalogPage } from "../views/CatalogCreate";
import CatalogLinksPage from "../views/CatalogsLinks";
import CreateProductPage from "../views/CatalogCreateProduct";
import CreateCategoryPage from "../views/CatalogCreateCategory";
import CatalogProductsPage from "../views/CatalogProducts";
import CatalogDeliveriesPage from "../views/CatalogDeliveries";
import CreateDeliveryPage from "../views/CatalogCreateDelivery";
import EditDeliveryPage from "../views/CatalogEditDelivery";
import CatalogOrdersPage from "../views/CatalogOrders";
import CatalogOrderDetailsPage from "../views/CatalogOrderDetails";
import CatalogCategoriesPage from "../views/CatalogCategories";
import CatalogEditProductPage from "../views/CatalogEditProduct";
import CatalogEditCategoryPage from "../views/CatalogEditCategory";
import { CatalogEditPage } from "../views/CatalogEdit";

const { Header, Sider, Content } = Layout;

export default function DashboardRoutes() {
  const history = useHistory();
  const [collapse, setCollapse] = useState(false);
  const dispatch = useDispatch();
  const { onBoardRoute, started } = useSelector(
    (state) => state.onboardLevelInfoReducer
  );

  const { onVerificationState } = useSelector(
    (state) => state.onboardingStateReducer
  );
  const [activeSections, setActiveSections] = useState<string[]>([]); //get the active areas based off percentage
  const [loading, setLoading] = useState(true); // New loading state
  const { meInfo } = useSelector((state) => state.meReducer);
  const defaultbusiness = meInfo?.subsidiaryDetails?.subsidiaries.find(
    (x: any) => x.isDefault
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);
  const getOnboardLevelMe = async () => {
    try {
      dispatch(openLoader());
      const data: any = await client.get(
        "/subsidiary/dashboard/onboarding/percentage"
      );

      // The following code below checks the percentage level to know if the user has started filling things
      //by checking the specific values and map them to sections
      const sectionsMap: Record<string, string> = {
        personalInformation: "Personal Information Section",
        businessInformation: "Business Information Section",
        businessCompliance: "Business Compliance Section",
        bankInformation: "Bank Information Section",
        termsAndCondition: "Terms and Condition Section",
      };

      const activeSections = Object.keys(sectionsMap).filter(
        (key) => data?.data?.data[key] !== 0
      );
      setActiveSections(activeSections); // Set the active sections in component state
      setLoading(false);
      // console.log('all active sections' ,activeSections)
      // console.log('length', activeSections.length)
      // if (activeSections.length > 0) {
      //   console.log("Sections initiated:", activeSections.map(key => sectionsMap[key]));
      //   // Dispatch an action to set the active sections in your store
      //   // dispatch(showActiveSections(activeSections.map(key => sectionsMap[key])));
      // }
      //the end of the section of the code

      dispatch(saveOnboardLevelInfo(data?.data));
    } catch (error: any) {
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        // const message = error?.response?.data?.message;
        // dispatch(
        //   openToastAndSetContent({
        //     toastContent: message,
        //     toastStyles: ToastErrorStyles,
        //   })
        // );
      }
    } finally {
      dispatch(closeLoader());
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getOnboardLevelMe();
  }, [history.location.pathname]);

  // useEffect(() => {
  //   console.log("started:", onVerificationState);
  //   console.log("route:", onBoardRoute);
  // }, [onVerificationState, onBoardRoute]);
  // console.log({ onVerificationState, onBoardRoute, defaultbusiness })

  //test responsive drawer switch
  // const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // {console.log('onboard route', onBoardRoute)}
    // {console.log('default business', defaultbusiness?.status )}
    // {console.log('on verification',onVerificationState)}

    // Automatically close Drawer when resizing to larger screens
    if (!isMobile) {
      setCollapse(false);
    }
  }, [isMobile]);

  //end

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <Layout
        style={{
          position: "relative",
          overflowY: "hidden",
          height: "100vh",
          background: "#f8f8f8",
        }}
      >
        {!isMobile ? (
          <Sider
            trigger={null}
            width="250px"
            breakpoint="md"
            collapsedWidth="0" // Collapse to 0 width
            onBreakpoint={(broken) => {
              setCollapse(broken);
            }}
            onCollapse={(collapsed, type) => {
              // console.log(collapsed, type);
            }}
            collapsible
            collapsed={collapse}
            className={styles.scrollbarhide}
            style={{
              zIndex: 10,
              height: "100vh",
              overflowY: "scroll",
              overflowX: "hidden",
              background: "#ffffff",
              position: collapse ? "fixed" : "relative", // Fixed when collapsed
              left: collapse ? 0 : "auto", // Positioned to the left
              top: 0,
              width: collapse ? "100vw" : "250px", // Full width when collapsed
              transition: "width 0.3s ease", // Smooth transition
            }}
          >
            <SideDrawer collapse={collapse} />
          </Sider>
        ) : (
          <Drawer
            placement="left"
            closable={false}
            onClose={() => setCollapse(false)}
            visible={collapse}
            bodyStyle={{ padding: 0 }}
            width="250px"
            className={styles.scrollbarhide}
          >
            <SideDrawer collapse={collapse} />
          </Drawer>
        )}
        {/* <Sider
        trigger={null}
        width="250px"
        breakpoint="md"
        collapsedWidth="0" // Collapse to 0 width
        onBreakpoint={(broken) => {
          setCollapse(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        collapsible
        collapsed={collapse}
        className={styles.scrollbarhide}
        style={{
          zIndex: 10,
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          background: "#ffffff",
          position: collapse ? 'fixed' : 'relative', // Fixed when collapsed
          left: collapse ? 0 : 'auto', // Positioned to the left
          top: 0,
          width: collapse ? '100vw' : '250px', // Full width when collapsed
          transition: 'width 0.3s ease', // Smooth transition
        }}
      >
        <SideDrawer collapse={collapse} />
      </Sider> */}

        {/* <Drawer
          placement="left"
          closable={false}
          onClose={() => setCollapse(false)}
          visible={collapse}
          bodyStyle={{ padding: 0 }}
          width="250px"
          className={styles.scrollbarhide}
        >
          <SideDrawer collapse={collapse} />
        </Drawer> */}

        <Layout style={{ margin: "0 0", background: "#F8F8F8" }}>
          <Header
            className={styles.antlayoutheader}
            style={{
              background: "white",
              height: "fit-content",
              minHeight: "80px",
            }}
            // style={{
            //   position: "sticky",
            //   zIndex: 1,
            //   width: "100%",
            //   top: 0,
            //   right: 0,
            //   background: "white",
            //   // height: "74px",
            //   height: "fit-content",
            //   padding: "0 20px",
            // }}
          >
            <NavBarHeading collapse={collapse} setCollapse={setCollapse} />
          </Header>
          <Content
            className={styles.scrollbarhide}
            style={{
              marginTop: "32px",
              overflowY: "scroll",
              margin: "20px 20px",
            }}
          >
            <Switch>
              {/* <Route exact path='/dashboard/'>
                {
                  defaultbusiness?.status == "Active" ? <Dashboard /> :

                    defaultbusiness?.status == "Pending-Approval" ? <Dashboard /> :
                    
                      onVerificationState !== 'Pending-Approval' && onBoardRoute ? (
                        <Redirect to='/dashboard/get-Started' />
                      ) : onVerificationState !== 'Pending-Approval' &&
                        !onBoardRoute ? (
                        <Redirect to='/dashboard/get-Started/onboarding' />
                      ) : (
                        <Dashboard />
                      )
                } 
              </Route> */}

              <Route exact path="/dashboard/">
                {defaultbusiness?.status == "Active" ||
                onVerificationState == "Active" ? (
                  <Dashboard />
                ) : defaultbusiness?.status == "new" ||
                  onVerificationState == "new" ? (
                  <Redirect to="/dashboard/get-Started" />
                ) : (defaultbusiness?.status == "Pending-Verification" ||
                    onVerificationState == "Pending-Verification") &&
                  activeSections.length === 0 ? (
                  <Redirect to="/dashboard/get-Started" />
                ) : defaultbusiness?.status == "Pending-Verification" &&
                  onVerificationState == "Pending-Verification" &&
                  activeSections.length > 0 ? (
                  <Redirect to="/dashboard/get-Started/onboarding" />
                ) : (
                  <Dashboard />
                )}
              </Route>
              <Route
                exact
                path="/dashboard/get-Started"
                component={GetStarted}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/onboarding"
                component={Onboarding}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/personalInformation"
                component={PersonalInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/businessInformation"
                component={BusinessInformation}
              ></Route>
              {/* Catalogue Routes */}
              <Route
                path="/dashboard/catalogue"
                component={CatalogLinksPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/create"
                component={CreateCatalogPage}
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/edit"
                component={CatalogEditPage}
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/products/:productId/edit"
                component={CatalogEditProductPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/products"
                exact
                component={CatalogProductsPage}
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/products/create"
                exact
                component={CreateProductPage}
              />
              <Route
                path="/dashboard/catalogue/categories/create"
                component={CreateCategoryPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/categories/:categoryId/edit"
                component={CatalogEditCategoryPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/delivery"
                component={CatalogDeliveriesPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/delivery/create"
                component={CreateDeliveryPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/:catalogueId/delivery/edit/:deliveryId"
                component={EditDeliveryPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/orders"
                component={CatalogOrdersPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/:orderId/orders"
                component={CatalogOrderDetailsPage}
                exact
              />
              <Route
                path="/dashboard/catalogue/categories"
                component={CatalogCategoriesPage}
                exact
              />
              {/* End of Catalogue Routes */}
              <Route
                exact
                path="/dashboard/get-Started/contactInformation"
                component={ContactInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/businessRegistration"
                component={BusinessRegistration}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/bankdetails"
                component={BankDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/terms"
                component={Terms}
              ></Route>
              <Route
                exact
                path="/dashboard/get-Started/tour"
                component={Tour}
              ></Route>

              <Route
                exact
                path="/dashboard/compliance"
                component={Compliance}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions"
                component={Transactions}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice"
                component={Invoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice/new-invoice"
                component={NewInvoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice/:id/details"
                component={InvoiceDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers"
                component={Customers}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products"
                component={Products}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products/new-product"
                component={NewProduct}
              ></Route>
              <Route
                exact
                path="/dashboard/business/payment-links"
                component={PaymentLinks}
              ></Route>
              <Route
                exact
                path="/dashboard/business/qr-codes"
                component={QrCodes}
              ></Route>
              <Route
                exact
                path="/dashboard/business/refunds"
                component={Refunds}
              ></Route>
              <Route
                exact
                path="/dashboard/business/disputes"
                component={Disputes}
              ></Route>
              <Route
                exact
                path="/dashboard/account/balances"
                component={Balances}
              ></Route>
              <Route
                exact
                path="/dashboard/balance/rolling-reserve"
                component={RollingReserve}
              ></Route>

              <Route
                exact
                path="/dashboard/balance/history"
                component={BalanceHistory}
              ></Route>
              <Route
                exact
                path="/dashboard/account/virtual-wallets"
                component={VirtualWallets}
              ></Route>
              <Route
                exact
                path="/dashboard/account/settlements"
                component={Settlements}
              ></Route>
              <Route
                exact
                path="/dashboard/account/settlements/grouped"
                component={SettlementGrouped}
              ></Route>
              <Route
                exact
                path="/dashboard/account/payouts"
                component={Payouts}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/transfer"
                component={Transfer}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/pending-approval"
                component={PendingApprovals}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/beneficiaries"
                component={Beneficiary}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/funding-history"
                component={Fundinghistory}
              ></Route>
              <Route exact path="/dashboard/refunds" component={Refund}></Route>

              <Route
                exact
                path="/dashboard/settings/business"
                component={Business}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/compliance-information"
                component={ComplianceInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/settlement-accounts"
                component={PayoutAccounts}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/roles-permissions"
                component={Roles}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/subsidiaries"
                component={Subsidiary}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/users"
                component={User}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/preference"
                component={Preferences}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/audit-trails"
                component={AuditTrails}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback"
                component={Chargeback}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/:id/details"
                component={ChargebackDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/pending"
                component={Pending}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/awaiting-response"
                component={Awaitingresponse}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/won"
                component={Won}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/lost"
                component={Lost}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/assessment"
                component={Assessment}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers/customer-details/:id"
                component={CustomerDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions/transaction-details/:id"
                component={TransactionDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/refunds/refund-details/:id"
                component={RefundDetails}
              ></Route>

              <Route
                exact
                path="/dashboard/settings/api-webhooks"
                component={Webhooks}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}
