import React from "react";

export interface PayoutsData {
  dateInitiated: string;
  recipientName: string;
  accountNumber: string;
  currency: string;
  sessionId: string;
  amount: string;
  fee: string;
  type: string;
  transferType: string,
  action: React.ReactNode;
  status: React.ReactNode;
}

export const ColumnPayouts = [
  {
    Header: "Payout date",
    accessor: "dateInitiated",
  },
  {
    Header: "Recipient",
    accessor: "recipientName",
  },
  {
    Header: "Beneficiary Number",
    accessor: "accountNumber",
  },
  {
    Header: "Session ID",
    accessor: "sessionId",
  },
  // {
  //   Header: "Currency",
  //   accessor: "currency",
  // },  
  {
    Header: "Total Amount",
    accessor: "amount",
  },
  {
    Header: "Transfer Type",
    accessor: "transferType",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Fee",
    accessor: "fee",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export interface PayoutsDataNew {
  bankName: string;
  accountNumber: string;
  accountName: string;
}

export const ColumnPayoutsNew = [
  {
    Header: 'Bank name',
    accessor: 'bankName',
  },
  {
    Header: 'Account number',
    accessor: 'accountNumber',
  },
  {
    Header: 'Account name',
    accessor: 'accountName',
  },
];
