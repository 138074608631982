import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogLinksComponent = lazy(() => import("./CatalogLinks"));

const CatalogLinksPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CatalogLinksComponent />
  </Suspense>
);

export default CatalogLinksPage;