import React, { useState, ReactNode, Fragment, useEffect, useRef } from 'react';
import styles from './QrCodeMenu.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import LinkIcon from '@mui/icons-material/Link';
import ViewIcon from '../../../assets/images/Icons/eyeIcon.svg';
import downloadIcon from '../../../assets/images/Icons/downloadIcon.svg';
import{ ReactComponent as SuccessIcon } from '../../../assets/images/Icons/successGreen.svg';
import{ ReactComponent as FailedIcon } from '../../../assets/images/Icons/failedIcon.svg';
import QrCodeOffIcon from '../../../assets/images/Icons/qrcodeoff.svg';
import qrCode from '../../../assets/images/Icons/qr_code.svg';
import mastercard from '../../../assets/images/Logos/mastercard.svg';
import visa from '../../../assets/images/Logos/visa.svg';
import transactpay from '../../../assets/images/Logos/transactpay_final_logo_icon1.png';
import verve from '../../../assets/images/Logos/verve.svg';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { getEnvironmentConfig } from '../../../utils/getEnvironmentConfig';
import { Download, KeyboardArrowDown, KeyboardDoubleArrowDown, Twitter, WhatsApp } from '@mui/icons-material';
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import html2canvas from 'html2canvas';
import QrViewModal from '../../ModalContent/QrViewModal/QrViewModal';
import transactworld from "../../../assets/images/Logos/twd.svg";


type MenuProps = {
	menuContent: string;
};
const QrCodeMenu = ({
	reference,
	status,
	fetchPaymentLink,
	dataInfo
}: any) => {
	const dispatch = useDispatch();
	const [referenceData, setReferenceData] = useState<any>();
	const [menuOption, setMenuOption] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
	const [modalIsOpenGenerateQr, setIsOpenGenerateQr] = React.useState(false);
	const [copied, setCopied] = useState(false);
	const [modalIsOpenSuccess, setIsOpenSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	const closeModalSuccess = () => {
		setIsOpenSuccess(false);
	};


	if (copied) {
		dispatch(
			openToastAndSetContent({
				toastContent: 'Payment link copied',
				toastStyles: ToastSuccessStyles,
			})
		);
	}
	function closeModal() {
		setIsOpen(false);
	}
	const closeModalDetails = () => {
		setIsOpenDetails(false);
	};
	const closeModalGenerateQr = () => {
		setIsOpenGenerateQr(false);
	};
	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	const body = {
		action: status === true ? 'disable' : 'activate',
		reference,
	};

	// const updatePaymentLink = async () => {
	// 	try {
	// 		const data: any = await client.post(
	// 			'/subsidiary/dashboard/payment/link/update-status',
	// 			body
	// 		);

	// 		const message = data?.data?.message;
	// 		fetchPaymentLink();
	// 		closeModal();
	// 		dispatch(
	// 			openToastAndSetContent({
	// 				toastStyles: ToastSuccessStyles,
	// 				toastContent: message,
	// 			})
	// 		);
	// 	} catch (error: any) {
	// 		const { message } = error?.response?.data;
	// 		dispatch(
	// 			openToastAndSetContent({
	// 				toastStyles: ToastErrorStyles,
	// 				toastContent: message,
	// 			})
	// 		);
	// 	}
	// };

	const updatePaymentLink = async () => {
		try {
			const data: any = await client.post(
				'/subsidiary/dashboard/payment/link/update-status',
				body
			);
		
			const message = data?.data?.message;
			fetchPaymentLink();
			closeModal();
	
			// Set the success message based on the action
			if (body.action === 'activate') {
				setSuccessMessage('QR Code Activated Successfully');
			} else {
				setSuccessMessage('QR Code Deactivated Successfully');
			}
	
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
	
			// Show the success modal
			setIsOpenSuccess(true);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};
	
	
	const getLinkByReference = () => {
		setIsOpenDetails(true);
		setReferenceData(dataInfo);
	};

	const copyPaymentLink = () => { };
	const handleStatus = (e: React.SyntheticEvent) => {
		e.preventDefault();
		closeModal();
		// getLinkByReference();
		updatePaymentLink();
	};	const qrRef = useRef<HTMLDivElement | null>(null);

	// const downloadQRCode = () => {
	// 	// Show the modal for a brief period
	// 	setIsOpenGenerateQr(true);
	  
	// 	// Delay download by 1 second
	// 	setTimeout(() => {
	// 	  if (qrRef.current) {
	// 		html2canvas(qrRef.current).then((canvas) => {
	// 		  const dataUrl = canvas.toDataURL('image/png');
	// 		  const link = document.createElement('a');
	// 		  link.href = dataUrl;
	// 		  link.download = `${dataInfo?.name || 'QR Code'}-QrCode.png`;
	// 		  link.click();
	// 		});
	// 	  }
	  
	// 	  // Close the modal after download
	// 	  setTimeout(() => {
	// 		setIsOpenGenerateQr(false);
	// 	  }, 500); // Allow time for download to initiate before closing
	// 	}, 1000); // Show modal for 1 second before downloading
	//   };

	  const downloadQRCode = () => {
		// Show the modal for a brief period
		setIsOpenGenerateQr(true);
	  
		// Delay download by 1 second
		setTimeout(() => {
		  if (qrRef.current) {
			html2canvas(qrRef.current, {
			  useCORS: true,
			//   allowTaint: true, 
			  scale: 2, // Higher resolution for better image quality
			}).then((canvas) => {
			  const dataUrl = canvas.toDataURL('image/png');
			  const link = document.createElement('a');
			  link.href = dataUrl;
			  link.download = `${dataInfo?.name || 'QR Code'}-QrCode.png`;
			  link.click();
			}).catch((error) => {
			  console.error('Error generating QR Code:', error);
			});
		  }
	  
		  // Close the modal after download
		  setTimeout(() => {
			setIsOpenGenerateQr(false);
		  }, 500); // Allow time for download to initiate before closing
		}, 1000); // Show modal for 1 second before downloading
	  };
	  

	const { activeSubsidiary } = useSelector(
		(state) => state.subsidiaryInfoReducer
	);
	  
	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							{/* <Menus
								icon={QrCodeOffIcon}
								optionName={
									status === true ? 'Deactivate QR Code' : 'Activate QR Code'
								}
								onclick={() => setIsOpen(true)}
							/> */}
							<Menus
								icon={ViewIcon}
								optionName='View QR Code'
								onclick={() => {
									getLinkByReference();
								}}
							/>
							<Menus
								icon={downloadIcon}
								optionName='Download Qr Code'
								// onclick={() => {
								// 	setIsOpenGenerateQr(!modalIsOpenGenerateQr);
								// }}
								onclick={() => {
									downloadQRCode();
								}}
							/>
							{/* <Menus
								icon={editPen}
								optionName='Edit Payment Link'
								onclick={() => {
									setIsOpenGenerateQr(!modalIsOpenGenerateQr);
								}}
							/> */}
						</div>
					)}
				</div>
			</ClickAwayListener>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}
			// className={styles.modal}
			>
				{status === true ? (
					<div className={styles.modalBody}>
						<div className={styles.modalHeader}>
							<FailedIcon style={{height:'90px', width:'90px'}}></FailedIcon>
						</div>
						<div className={styles.modalContent}>
							<div className={styles.confirmation}>
								Deactivate QR Code
							</div>
							<div className={styles.link}>
								You are about to deactivate this QR code.
							</div>
							<div className={styles.link}>
								Do you want to continue with this action? 
							</div>
						</div>
						<div className={styles.modalFooter}>
							<div
								className={styles.deactivate}
								style={{border:'1px solid #8e173e'}}
								onClick={(e) => {
									handleStatus(e);
								}}>
								Yes
							</div>
							<div className={styles.cancel} onClick={closeModal}>
								No
							</div>
						</div>
					</div>
				) : (
					<div className={styles.modalBody}>
						<div className={styles.modalHeader}>
							<SuccessIcon style={{height:'90px', width:'90px'}}></SuccessIcon>
						</div>
						<div className={styles.modalContent}>
							<div className={styles.confirmation}>
								Are you sure you want to Activate this link?
							</div>
							<div className={styles.link}>
								Activating this link will make it accessible.
							</div>
						</div>
						<div className={styles.modalFooter}>
							<div
								style={{border:'1px solid #8e173e'}}
								className={styles.deactivate}
								onClick={(e) => {
									handleStatus(e);
								}}>
								Yes, activate link
							</div>
							<div className={styles.cancel} onClick={closeModal}>
								No
							</div>
						</div>
					</div>
				)}
			</Modal>

			<Modal
				isOpen={modalIsOpenDetails}
				onRequestClose={closeModalDetails}
				contentLabel='Example Modal'
				style={customStyles}
			// className={styles.modal}
			>
				<QrViewModal
					closeModal={closeModalDetails}
					// reference={referenceData}
					reference={{
						...dataInfo, // Contains the payment link details
						bankName: dataInfo.bankName, // Add bankName explicitly
						accountNumber: dataInfo.accountNumber, // Add accountNumber explicitly 
					}}
				/>
			</Modal>

			{/* modal for qr code download */}
			<Modal
				isOpen={modalIsOpenGenerateQr}
				onRequestClose={closeModalGenerateQr}
				style={customStyles}
				contentLabel='Generate Qr Code'
			>
				<div className={styles.modalBody}>
					<div ref={qrRef} style={{padding: '20px'}} className={styles.qrCodeModal}>
						{/* QR Code Information */}
						<div style={{ textAlign: "center", marginBottom: "10px" }}>
						<div style={{ height: "80px", width: "80px", borderRadius: "100%", margin: "10px auto", }}> 
							<img src={activeSubsidiary?.subsidiaryLogo ? activeSubsidiary?.subsidiaryLogo : transactpay} alt="Logo" style={{objectFit:'contain', width:'100%', height:'100%'}} />
						</div>
						<h3 style={{ fontSize: "x-large" }}>Scan QR Code</h3>
						<h3 style={{ color: "#8e173e", fontSize: "large" }}>
							{reference.accountNumber} {''} {reference.bankName}
						</h3>
						<h3 style={{ color: "#8e173e", fontSize: "small" }}>{dataInfo?.linkName}</h3>
						</div>

						{/* Display QR Code */}
						<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", margin: "20px auto", backgroundColor: "#8E183E33", padding: "20px", borderRadius: "8px", }} >
							<QRCodeCanvas value={dataInfo?.linkUrl} size={260} />
						</div>

						{/* Custom Footer */}
						<div style={{ marginTop: "20px", textAlign: "center" }}>
						<p style={{ marginTop: "10px", fontSize: "medium" }}>
							Powered by
							<img
							src={transactworld}
							alt="Transactpay"
							style={{ width: "100px", marginLeft: "5px" }}
							/>
						</p>
						</div>
					</div>
				</div>
				
			</Modal>

			<Modal
				isOpen={modalIsOpenSuccess}
				onRequestClose={closeModalSuccess}
				contentLabel="Success Modal"
				style={customStyles}
			>
				<div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<SuccessIcon style={{ height: '90px', width: '90px' }} />
					</div>
					<div className={styles.modalContent}>
						<div className={styles.confirmation}>{successMessage}</div>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModalSuccess}>
							Close
						</div>
					</div>
				</div>
			</Modal>

		</Fragment>
	);
};

export default QrCodeMenu;
