import React, { useEffect } from "react";
import styles from "./RefundTransaction.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import Select from "react-select";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const RefundTransaction = ({ closeModal, fetchPaymentLink, cb }: any) => {
  const dispatch = useDispatch();

  const [paymentTypes, setPaymentTypes] = React.useState<any>([]);
  const [currencies, setCurrencies] = React.useState<any>([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();

  interface initTypes {
    amount: number | undefined;
    currency: string | undefined;
    reference: string | undefined;
  }

  const initProps: initTypes = {
    amount: undefined,
    currency: undefined,
    reference: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.reference && inputs.amount) return true;
    return false;
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const RefundTransaction = async () => {
    try {
      dispatch(openLoader());
      const data: any = await client.post('/subsidiary/dashboard/refund', [{
        ...inputs,
        // orderId: transactionId,
        transactionReference: inputs.reference
      }]);
      const message = data?.data?.message;
      closeModal?.();
      cb();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      closeModal?.();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader());
    }
  };

  const fetchPaymentLinkTypes = async () => {
    try {
      const data: any = await client.get(
        "/subsidiary/dashboard/payment/link/types"
      );
      console.log(data?.data?.paymentLinkTypes)
      setPaymentTypes(data?.data?.paymentLinkTypes);
    } catch (error: any) {
      const { message } = error?.response?.data ?? {};
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response: any = await client.get(
        "/subsidiary/dashboard/service/currencies"
      );
      let currencies = response?.data?.data;
      currencies = currencies.sort((a: any, b: any) => {
        if (a.shortName === "NGN") return -1;
        if (b.shortName === "NGN") return 1;
        return 0;
      });
      setCurrencies(response?.data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  let selectedPaymentTypeOptions = paymentTypes.map((selectedPaymentType: any) => {
    let uiName = selectedPaymentType.paymentLinkName;

    if (uiName === "Single Charge") {
      uiName = "One Off";
    } else if (uiName === "Multiple Charge") {
      uiName = "Multiple Use";
    }

    return {
      value: selectedPaymentType.code,
      label: uiName,
      // label: selectedPaymentType.paymentLinkName,
    };
  });

  let currencyOptions = currencies.map(function (selectedcurrency: any) {
    return {
      value: selectedcurrency.shortName,
      label: selectedcurrency.name,
    };
  });


  useEffect(() => {
    fetchPaymentLinkTypes();
    fetchCurrencies();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    RefundTransaction();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Refund Transaction</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="reference"
            inputLabel="Order Reference"
            inputValue={inputs.reference}
          />

          {/* <Select
            defaultValue={selectedCurrency}
            onChange={setSelectedCurrency}
            options={currencyOptions}
            placeholder="Currency"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: "300px",
              }),
            }}
          /> */}

          <OutlineTextInput
            notRequired={true}
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Refund Amount"
            inputValue={inputs.amount}
          />
        </form>
      </div>

      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Refund Transaction
          </div>
        ) : (
          <div className={styles.notClickable}>Refund Transaction</div>
        )}
      </div>
    </div>
  );
};

export default RefundTransaction;
