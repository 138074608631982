import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogDeliveriesComponent = lazy(() => import("./CatalogDeliveries"));

const CatalogDeliveriesPage = () => (
    <Suspense fallback={<LoaderPage />}>
        <CatalogDeliveriesComponent />
    </Suspense>
)

export default CatalogDeliveriesPage;