import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CreateCatalogComponent = lazy(() => import("./CreateCatalog"));

export const CreateCatalogPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CreateCatalogComponent />
  </Suspense>
);
