import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogOrdersComponent = lazy(() => import("./CatalogOrders"));

const CatalogOrdersPage = () => (
    <Suspense fallback={<LoaderPage />}>
        <CatalogOrdersComponent />
    </Suspense>
)

export default CatalogOrdersPage;