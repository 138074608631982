import React from "react";

export interface InvoiceData {
  title: string;
  reference: string;
  customerName: string;
  amount: string;
  dueDate: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnInvoice = [
  {
    Header: "Title",
    accessor: "title",
  }, 
  {
    Header: "Customer Name",
    accessor: "customerName",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },

  {
    Header: "Due date",
    accessor: "dueDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
