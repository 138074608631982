import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CatalogEditComponent = lazy(() => import("./CatalogEdit"));

export const CatalogEditPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CatalogEditComponent />
  </Suspense>
);
