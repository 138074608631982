import { lazy, Suspense } from "react";
import LoaderPage from "../../components/LoaderPage";

const CreateProductComponent = lazy(() => import("./CreateProduct"));

const CreateProductPage = () => (
  <Suspense fallback={<LoaderPage />}>
    <CreateProductComponent />
  </Suspense>
);

export default CreateProductPage;
