import React, { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";
import styles from "./QrViewModal.module.scss";
import mastercard from "../../../assets/images/Logos/mastercard.svg";
import visa from "../../../assets/images/Logos/visa.svg";
import verve from "../../../assets/images/Logos/verve.svg";
import transactpay from "../../../assets/images/Logos/transactpay_final_logo_icon1.png";
import transactworld from "../../../assets/images/Logos/twd.svg";
import { Close, Download } from "@mui/icons-material";
import { useSelector } from "react-redux";

type PaymentProps = {
  closeModal: () => void;
  reference: {
    logo: string;
    linkUrl: string;
    qrCodeUrl: string;
    linkName: string;
    bankName: string;
    accountNumber: string;
  };
};

const QrViewModal = ({ closeModal, reference }: PaymentProps) => {
  const qrCodeContainerRef = useRef<HTMLDivElement | null>(null);

  // const downloadVisibleQRCode = () => {
  //   if (!qrCodeContainerRef.current) {
  //     console.error("QR Code container reference is not set.");
  //     return;
  //   }

  //   // Temporarily hide the close button
  //   const closeButton = document.querySelector("button[aria-label='close']") as HTMLElement;
  //   if (closeButton) {
  //     closeButton.style.display = "none"; // Hide the close button
  //   }

  //   // Render the visible QR code section
  //   html2canvas(qrCodeContainerRef.current)
  //     .then((canvas) => {
  //       const dataUrl = canvas.toDataURL("image/png");
  //       const link = document.createElement("a");
  //       link.href = dataUrl;
  //       link.download = `${reference.linkName || "QR Code"}-VisibleQRCode.png`;
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error("Failed to generate visible QR code:", error);
  //     })
  //     .finally(() => {
  //       // Restore the close button visibility
  //       if (closeButton) {
  //         closeButton.style.display = ""; // Reset display style
  //       }
  //     });
  // };

  const downloadVisibleQRCode = () => {
    if (!qrCodeContainerRef.current) {
      console.error("QR Code container reference is not set.");
      return;
    }
  
    // Temporarily hide the close button
    const closeButton = document.querySelector("button[aria-label='close']") as HTMLElement;
    if (closeButton) {
      closeButton.style.display = "none"; // Hide the close button
    }
  
    // Use html2canvas with useCORS and image loading
    html2canvas(qrCodeContainerRef.current, {
      useCORS: true, // Allow cross-origin images
      onclone: (clonedDoc) => {
        // Ensure images inside the cloned element are fully loaded
        const images = clonedDoc.querySelectorAll("img");
        images.forEach((img) => {
          if (!img.complete) {
            img.onload = () => console.log(`Image loaded: ${img.src}`);
            img.onerror = () => console.error(`Failed to load image: ${img.src}`);
          }
        });
      },
    })
      .then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${reference.linkName || "QR Code"}-VisibleQRCode.png`;
        link.click();
      })
      .catch((error) => {
        console.error("Failed to generate visible QR code:", error);
      })
      .finally(() => {
        // Restore the close button visibility
        if (closeButton) {
          closeButton.style.display = ""; // Reset display style
        }
      });
  };
  

  const { activeSubsidiary } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );
  
  return (
    <div className={styles.modalBody}>
      {/* Close Button */}
      <div style={{ position: "absolute", right: "10px", top: "10px" }}>
        <button onClick={closeModal} style={{ border: "none", background: "transparent" }}>
          <Close />
        </button>
      </div>
      <div ref={qrCodeContainerRef} style={{padding: '20px'}} className={styles.qrCodeModal}>
        {/* QR Code Information */}
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <div
            style={{
              height: "80px",
              width: "80px",
              borderRadius: "100%",
              // border: "2px solid black",
              margin: "10px auto",
            }}> 
            <img src={activeSubsidiary?.subsidiaryLogo ? activeSubsidiary?.subsidiaryLogo : transactpay} alt="Logo" style={{objectFit:'contain', width:'100%', height:'100%'}} />
          </div>
          <h3 style={{ fontSize: "x-large" }}>Scan QR Code</h3>
          <h3 style={{ color: "#8e173e", fontSize: "large" }}>
            {reference.accountNumber} {''} {reference.bankName}
          </h3>
          <h3 style={{ color: "#8e173e", fontSize: "small" }}>{reference.linkName}</h3>
        </div>

        {/* Display QR Code */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            margin: "20px auto",
            backgroundColor: "#8E183E33",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <QRCodeCanvas value={reference.linkUrl} size={260} />
        </div>

        {/* Custom Footer */}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            style={{
              color: "#8e173e",
              fontSize: "small",
              margin: "10px auto",
              background: "transparent",
              border: "none",
              width: "auto",
            }}
            onClick={downloadVisibleQRCode}
          >
            Download
            <Download style={{ marginRight: "5px" }} />
          </button>
          <p style={{ marginTop: "10px", fontSize: "medium" }}>
            Powered by
            <img
              src={transactworld}
              alt="Transactpay"
              style={{ width: "100px", marginLeft: "5px" }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default QrViewModal;



      {/* Hidden QR Code for Download */}
      // <div className={styles.modalContent} ref={hiddenDownloadRef}
      //   style={{ position: "absolute", top: "-9999px", left: "-9999px", visibility: "hidden",
      //   }} >
      //   <div
      //     style={{ fontSize: "large", marginBottom: "10px",textAlign:'center' , fontWeight: "700", }} >
      //     {reference.linkName}
      //   </div>
      //   <div className={styles.qrcontainer}>
      //     <p style={{ fontSize: "large", fontWeight: "500", margin: "15px 0px" }}>
      //       Scan here to pay
      //     </p>
      //     <div className={styles.qrbox}>
      //       <QRCodeCanvas
      //         value={reference.linkUrl}
      //         size={120}
      //         title="Transactpay Payment Link"
      //       />
      //     </div>
      //     <p style={{ fontSize: "large", fontWeight: "500", marginTop: "5px" }}>
      //       We accept:
      //     </p>
      //     <div className={styles.iconBox}>
      //       <img
      //         src={mastercard}
      //         className={styles.qrIcon}
      //         alt="Mastercard"
      //         crossOrigin="anonymous"
      //       />
      //       <img
      //         src={verve}
      //         className={styles.qrIcon}
      //         alt="Verve"
      //         crossOrigin="anonymous"
      //       />
      //       <img
      //         src={visa}
      //         className={styles.qrIcon}
      //         alt="Visa"
      //         crossOrigin="anonymous"
      //       />
      //     </div>
      //   </div>
      //   <div
      //     style={{
      //       fontSize: "14",
      //       marginTop: "10px",
      //       color: "#8E173E",
      //     }}
      //   >
      //     Powered by
      //     <img
      //       src={transactpay}
      //       style={{
      //         width: "18px",
      //         height: "18px",
      //         marginLeft: "6px",
      //         objectFit: "contain",
      //       }}
      //       alt="Transactpay Logo"
      //     />
      //   </div>
      // </div>