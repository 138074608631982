import React, { useState, useRef } from "react";
import styles from "./CreateQrCode.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/Icons/successGreen.svg";
import { ReactComponent as CatalogueIcon } from "../../../assets/images/Icons/whitecatalogue.svg";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";
import { ArrowBack, KeyboardDoubleArrowDown } from "@mui/icons-material";
import mastercard from '../../../assets/images/Logos/mastercard.svg';
import visa from '../../../assets/images/Logos/visa.svg';
import transactpay from '../../../assets/images/Logos/transactpay_final_logo_icon1.png';
import verve from '../../../assets/images/Logos/verve.svg';

interface PaymentLink {
  id: number;
  name: string;
  url: string;
}

interface CreateQrCodeProps {
  closeModal: () => void;
  fetchPaymentLink: () => void;
  paymentLinks: PaymentLink[]; // Passed-in payment links
}

const CreateQrCode: React.FC<CreateQrCodeProps> = ({ closeModal, fetchPaymentLink, paymentLinks }) => {

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedLinkType, setSelectedLinkType] = useState<string | null>(null);
  const [selectedPaymentLink, setSelectedPaymentLink] = useState<any | null>(null); 
  const [selectedCatalogue, setSelectedCatalogue] = useState<any | null>(null);
  const [noItems, setNoItems] = useState<boolean>(false);

  const qrRef = useRef<HTMLDivElement | null>(null);
  const hiddenDownloadRef = useRef<HTMLDivElement | null>(null);

  const catalogueLinks: any[] = [];

  const handleBack = (): void => {
    if (noItems) {
      setNoItems(false);
      setCurrentStep(2);
    } else if (currentStep === 1) {
      closeModal(); // Close the entire modal
    } else if (currentStep === 3) {
      setCurrentStep(2); // Go back to selection
    } else {
      setCurrentStep(1); // Reset to the first step
      setSelectedPaymentLink(null);
      setSelectedCatalogue(null);
    }
  };

  const handleNext = (): void => {
    if (currentStep === 1) {
      setCurrentStep(2); // Move to link selection
    } else if (currentStep === 2) {
      if (selectedLinkType === "Payment Link" && paymentLinks.length === 0) {
        setNoItems(true); // No Payment Links
      } else if (selectedLinkType === "Catalogue Link" && catalogueLinks.length === 0) {
        setNoItems(true); // No Catalogues
      } else if ((selectedLinkType === "Payment Link" && selectedPaymentLink) || (selectedLinkType === "Catalogue Link" && selectedCatalogue)) {
        setCurrentStep(3); // Move to QR code generation screen
      }
    }
  };

  const handleLinkTypeSelect = (type: string): void => {
    setSelectedLinkType(type);
  };

  const handlePaymentLinkSelect = (link: any): void => {
    setSelectedPaymentLink(link);
  };

  const handleCatalogueSelect = (catalogue: any): void => {
    setSelectedCatalogue(catalogue);
  };

  const downloadStyledQRCode = () => {
    if (!hiddenDownloadRef.current) {
      console.error("Hidden download reference is not set.");
      return;
    }
  
    // Make the element temporarily visible
    hiddenDownloadRef.current.style.visibility = "visible";
    hiddenDownloadRef.current.style.position = "static";
  
    html2canvas(hiddenDownloadRef.current).then((canvas) => {
      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${selectedPaymentLink?.name || 'Catalogue'}-QRCode.png`;
      link.click();
  
      // Re-hide the element safely
      if (hiddenDownloadRef.current) {
        hiddenDownloadRef.current.style.visibility = "hidden";
        hiddenDownloadRef.current.style.position = "absolute";
      }
    }).catch((error) => {
      console.error("Failed to generate QR code:", error);
    });
  };

  // Use the `paymentLinkUrl` property for QR code generation
  const qrCodeUrl =
    selectedLinkType === "Payment Link"
      ? selectedPaymentLink?.paymentLinkUrl
      : selectedLinkType === "Catalogue Link"
      ? selectedCatalogue?.paymentLinkUrl
      : "";

  return (
    <div className={styles.modalBody}>

      {/* No Items Page/Screen */}
      {noItems && (
        <div className={styles.noItemsScreen}>
          <h3>{selectedLinkType === "Payment Link" ? "No Payment Links Found" : "No Catalogue Links Found"}</h3>
          <p>
            {selectedLinkType === "Payment Link"
              ? "You do not have any payment links. Do you want to create a new one?"
              : "You do not have any catalogue links. Do you want to create a new one?"}
          </p>
          <div className={styles.buttons}>
            <button
              className={styles.initiateButton}
              onClick={() =>
                window.location.href =
                  selectedLinkType === "Payment Link"
                    ? "/payment-link-page"
                    : "/catalogue-page"
              }
            >
              Yes
            </button>
            <button
              className={styles.cancelButton}
              onClick={() => {
                setNoItems(false); // Reset to the previous step
              }}
            >
              No
            </button>
          </div>
        </div>
      )}

      {/* Top Back Button */}
      {!noItems && currentStep == 2  && (
        <div>
            <button
              style={{ color: '#8e173e',fontSize:'18px', margin:'10px 0px',background:'transparent', border:'none' ,display:'flex', alignItems:'center', justifyContent:'center' }}
              onClick={handleBack}
            >
              <ArrowBack style={{marginRight:"5px"}}></ArrowBack> Back
            </button>
        </div>
      )}

      {/* No Items Screen */}
      {noItems && (
        <div className={styles.noItemsScreen}>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <SuccessIcon style={{ height: "80px", width: "80px" }} />
          </div>
          <h3 style={{ textAlign: "center" }}>
            {selectedLinkType === "Payment Link" ? "No Payment Link Found" : "No Catalogue Found"}
          </h3>
          <p style={{ textAlign: "center", margin: "10px 0", fontSize: "16px" }}>
            {selectedLinkType === "Payment Link"
              ? "You do not have a payment link attached to your merchant account. Do you want to create a new one?"
              : "You do not have a catalogue link created yet. Do you want to create a new one?"}
          </p>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "20px" }}>
            <button
              className={styles.cancelButton}
              onClick={() => window.location.href = "/catalogue-page"} // Update with actual catalogue page URL
            >
              {selectedLinkType === "Payment Link" ? "No" : "Go to Catalogues"}
            </button>
            <button
              className={styles.initiateButton}
              onClick={() => window.location.href = "/payment-link-page"} // Update with actual payment link page URL
            >
              {selectedLinkType === "Payment Link" ? "Create Payment Link" : "Create Catalogue"}
            </button>
          </div>
        </div>
      )}

      {/* Step 1: Select Link Type */}
      {!noItems && currentStep === 1 && (
        <div>
          <div className={styles.modalHeader}>
            <div className={styles.headerContent}>
              <h3>Create New QR Code</h3>
              <p style={{ fontWeight: "600", fontSize: "large" }}>
                Select QR code type to be created.
              </p>
            </div>
          </div>

          <div style={{background:'#AAAAAA1A', padding:'20px',borderRadius:'8px'}} className={styles.paymentOptionListWrapper}>
            <label
              className={`${styles.radioSelection} ${
                selectedLinkType === "Payment Link" ? styles.selected : ""
              }`}
              onClick={() => handleLinkTypeSelect("Payment Link")}
            >
              <div className={styles.icon}>
                <LinkIcon />
              </div>
              <div className={styles.radioLabel}>Payment Link</div>
              <input
                type="radio"
                name="linkType"
                value="Payment Link"
                checked={selectedLinkType === "Payment Link"}
                readOnly
              />
            </label>
            <label
              className={`${styles.radioSelection} ${
                selectedLinkType === "Catalogue Link" ? styles.selected : ""
              }`}
              onClick={() => handleLinkTypeSelect("Catalogue Link")}
            >
              <div className={styles.icon}>
                <CatalogueIcon />
              </div>
              <div className={styles.radioLabel}>Catalogue Link</div>
              <input
                type="radio"
                name="linkType"
                value="Catalogue Link"
                checked={selectedLinkType === "Catalogue Link"}
                readOnly
              />
            </label>
          </div>
        </div>
      )}

      {/* Step 2: Select Specific Link */}
      {!noItems && currentStep === 2 && (
        <div>
          <div className={styles.modalHeader}>
            <div className={styles.headerContent}>
              <h3>{selectedLinkType === "Payment Link" ? "Select Payment Link" : "Select Catalogue"}</h3>
              <p style={{ fontWeight: "600", fontSize: "large" }}>
                Kindly select the {selectedLinkType === "Payment Link" ? "payment link" : "catalogue link"} to be generated as a QR Code
              </p>
            </div>
          </div>

          <div className={styles.paymentOptionListWrapper}>
            {selectedLinkType === "Payment Link" && paymentLinks && paymentLinks.length > 0 ? (
              paymentLinks.map((link, index) => (
                <label
                  key={link.id}
                  className={`${styles.radioSelection} ${
                    selectedPaymentLink?.id === link.id ? styles.selected : ""
                  }`}
                  onClick={() => handlePaymentLinkSelect(link)}
                >
                  <div className={styles.icon}>{index + 1}</div>
                  <div className={styles.radioLabel}>{link.name}</div>
                  <input
                    type="radio"
                    name="paymentLink"
                    value={link.id}
                    checked={selectedPaymentLink?.id === link.id}
                    readOnly
                  />
                </label>
              ))
            ) : selectedLinkType === "Catalogue Link" && catalogueLinks.length > 0 ? (
              catalogueLinks.map((catalogue, index) => (
                <label
                  key={catalogue.id}
                  className={`${styles.radioSelection} ${
                    selectedCatalogue?.id === catalogue.id ? styles.selected : ""
                  }`}
                  onClick={() => handleCatalogueSelect(catalogue)}
                >
                  <div className={styles.icon}>{index + 1}</div>
                  <div className={styles.radioLabel}>{catalogue.name}</div>
                  <input
                    type="radio"
                    name="catalogue"
                    value={catalogue.id}
                    checked={selectedCatalogue?.id === catalogue.id}
                    readOnly
                  />
                </label>
              ))
            ) : (
              <div className={styles.noLinksWarning}>
                <p style={{ color: "#e6a700" }}>No links available.</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Step 3: QR Code Generation */}
      {!noItems && currentStep === 3 && (
        <div className={styles.qrCodeModal}>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ padding: '5px', marginBottom: '10px' }}>
              <SuccessIcon style={{ height: '90px', width: '90px' }} />
            </div>

            <h3 style={{ fontSize: 'x-large' }}>
              {selectedLinkType === "Payment Link" ? "Payment QR Code Generated Successfully" : "Catalogue QR Code Generated Successfully"}
            </h3>
          </div>

          {/* Main qr for display -- not downloaded */}
          <div ref={qrRef} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <QRCodeCanvas value={qrCodeUrl} size={150} />
          </div>

          {/* second hidden qr for download */}
          <div className={styles.modalContent} ref={hiddenDownloadRef} style={{ position: "absolute", top: "-9999px", left: "-9999px", visibility: "hidden" }}>
            <div style={{ fontSize: 'large', marginBottom: '10px', fontWeight: '700' }}>{selectedPaymentLink?.name}</div>
            <div className={styles.qrcontainer}>
              <p style={{ fontSize: 'large', fontWeight: '500', margin: '15px 0px' }}>Scan here to pay <KeyboardDoubleArrowDown /></p>
              <div className={styles.qrbox}>  <QRCodeCanvas value={selectedPaymentLink?.paymentLinkUrl || selectedCatalogue?.paymentLinkUrl} size={120} title='Transactpay Payment Link' /></div>
              <p style={{ fontSize: 'large', fontWeight: '500', marginTop: '5px' }}>We accept:</p>
              <div className={styles.iconBox}> <img src={mastercard} className={styles.qrIcon} alt='Mastercard' crossOrigin="anonymous" /> <img src={verve} className={styles.qrIcon} alt='Verve' crossOrigin="anonymous" /> <img src={visa} className={styles.qrIcon} alt='Visa' crossOrigin="anonymous" />  </div>
            </div>
            <div style={{ fontSize: '14', marginTop: '10px', color: '#8E173E' }}>Powered by <img src={transactpay} style={{ width: '18px', height: '18px', marginLeft: '6px', objectFit: 'contain' }} alt='Transactpay Logo' /></div>
          </div>


          <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "10px" }}>
            <button style={{ border: "2px solid #8e173e" }} className={styles.cancelButton} onClick={downloadStyledQRCode}> Download </button>
            <button onClick={closeModal} className={styles.initiateButton}> Close </button>
          </div>
        </div>
      )}

      {/* Navigation Buttons */}
      {!noItems && (
        <div className={styles.modalFooter}>
          {currentStep !== 3 && (
            <button
              style={{ border: "2px solid #8e173e" }}
              className={styles.cancelButton}
              onClick={closeModal}
            >
              Cancel
            </button>
          )}

          {currentStep !== 3 && (
            <button
              className={`${styles.initiateButton} ${
                (currentStep === 1 && !selectedLinkType) ||
                (currentStep === 2 &&
                  selectedLinkType === "Payment Link" &&
                  !selectedPaymentLink) ||
                (currentStep === 2 &&
                  selectedLinkType === "Catalogue Link" &&
                  !selectedCatalogue)
                  ? styles.disabledButton
                  : ""
              }`}
              onClick={handleNext}
              disabled={
                (currentStep === 1 && !selectedLinkType) ||
                (currentStep === 2 &&
                  selectedLinkType === "Payment Link" &&
                  !selectedPaymentLink) ||
                (currentStep === 2 &&
                  selectedLinkType === "Catalogue Link" &&
                  !selectedCatalogue)
              }
            >
              {currentStep === 2 ? "Generate QR Code" : "Next"}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateQrCode;
