import { ReactComponent as DashboardIcon } from "../assets/images/Icons/dashboard.svg";
import { ReactComponent as ComplianceIcon } from "../assets/images/Icons/compliance.svg";
import { ReactComponent as TransactionIcon } from "../assets/images/Icons/transactions.svg";
import { ReactComponent as CustomerIcon } from "../assets/images/Icons/customers.svg";
import { ReactComponent as ProductIcon } from "../assets/images/Icons/products.svg";
import { ReactComponent as PaymentIcon } from "../assets/images/Icons/payment-links.svg";
import { ReactComponent as RefundIcon } from "../assets/images/Icons/refunds.svg";
import { ReactComponent as BalancesIcon } from "../assets/images/Icons/balances.svg";
import { ReactComponent as VirtualIcon } from "../assets/images/Icons/virtual-wallets.svg";
import { ReactComponent as SettlementIcon } from "../assets/images/Icons/settlements.svg";
import { ReactComponent as PayoutIcon } from "../assets/images/Icons/payouts.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/Icons/profile.svg";
import { ReactComponent as PayoutAccountIcon } from "../assets/images/Icons/payout-account.svg";
import { ReactComponent as RolesIcon } from "../assets/images/Icons/roles-permissions.svg";
import { ReactComponent as UserIcon } from "../assets/images/Icons/users-subsidiaries.svg";
import { ReactComponent as PreferencesIcon } from "../assets/images/Icons/preferences.svg";
import { ReactComponent as AuditIcon } from "../assets/images/Icons/audit-trails.svg";
import { ReactComponent as ApiIcon } from "../assets/images/Icons/api-webhooks.svg";
import { ReactComponent as InvoiceIcon } from "../assets/images/Icons/invoices.svg";
import { ReactComponent as PendingApprovalIcon } from "../assets/sidedrawericons/pendingapproval.svg";
import { ReactComponent as BalanceHistoryIcon } from "../assets/sidedrawericons/balancehistory.svg";
import { ReactComponent as AllChargeBackIcon } from "../assets/sidedrawericons/allchargeback.svg";
import { ReactComponent as PendingIcon } from "../assets/sidedrawericons/pending.svg";
import { ReactComponent as AwaitingResponseIcon } from "../assets/sidedrawericons/awaitingresponse.svg";
import { ReactComponent as WonIcon } from "../assets/sidedrawericons/won.svg";
import { ReactComponent as LostIcon } from "../assets/sidedrawericons/lost.svg";
import { ReactComponent as AssessmentIcon } from "../assets/sidedrawericons/assessment.svg";
import { ReactComponent as CatalogLinkIcon } from "../assets/sidedrawericons/link-icon.svg";
import { ReactComponent as OrderLinkIcon } from "../assets/sidedrawericons/catalogue-order-icon.svg";

import { ReactComponent as QrIcon } from "../assets/sidedrawericons/qrCode.svg";

import { v4 as uuidv4 } from "uuid";

export const SidebarRoutes = [
  {
    id: uuidv4(),
    name: "Home",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Dashboard",
        route: "/dashboard",
        icon: <DashboardIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Compliance",
      //   route: "/dashboard/compliance",
      //   icon: <ComplianceIcon />,
      // },
    ],
  },
  {
    id: uuidv4(),
    name: "Business",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Transactions",
        route: "/dashboard/business/transactions",
        icon: <TransactionIcon />,
      },
      {
        id: uuidv4(),
        name: "Invoices",
        route: "/dashboard/business/invoice",
        icon: <InvoiceIcon />,
      },
      {
        id: uuidv4(),
        name: "Customers",
        route: "/dashboard/business/customers",
        icon: <CustomerIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Products",
      //   route: "/dashboard/business/products",
      //   icon: <ProductIcon />,
      // },
      {
        id: uuidv4(),
        name: "Payment Links",
        route: "/dashboard/business/payment-links",
        icon: <PaymentIcon />,
      },
      {
        id: uuidv4(),
        name: "QR Codes",
        route: "/dashboard/business/qr-codes",
        icon: <QrIcon />,
      },
    ],
  },
  {
    name: "Catalogue",
    id: uuidv4(),
    subRoutes: [
      {
        id: uuidv4(),
        name: "Links",
        route: "/dashboard/catalogue",
        icon: <CatalogLinkIcon />,
      },
      {
        id: uuidv4(),
        name: "Orders",
        route: "/dashboard/catalogue/orders",
        icon: <OrderLinkIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Account",
    subRoutes: [
      // {
      //   id: uuidv4(),
      //   name: "Balances",
      //   route: "/dashboard/account/balances",
      //   icon: <BalancesIcon />,
      // },
      {
        id: uuidv4(),
        name: "Virtual Wallets",
        route: "/dashboard/account/virtual-wallets",
        icon: <VirtualIcon />,
      },
      {
        id: uuidv4(),
        name: "Settlements",
        route: "/dashboard/account/settlements/grouped",
        icon: <SettlementIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Grouped Settlement",
      //   route: "/dashboard/account/settlements/grouped",
      //   icon: <SettlementIcon />,
      // },
    ],
  },
  {
    id: uuidv4(),
    name: "Balance",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Balances",
        route: "/dashboard/account/balances",
        icon: <BalancesIcon />,
      },
      {
        id: uuidv4(),
        name: "Balance History",
        route: "/dashboard/balance/history",
        icon: <BalanceHistoryIcon />,
      },
      {
        id: uuidv4(),
        name: "Rolling Reserve",
        route: "/dashboard/balance/rolling-reserve",
        icon: <BalanceHistoryIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Payouts",
      //   route: "/dashboard/account/payouts",
      //   icon: <PayoutIcon />,
      // },
    ],
  },
  {
    id: uuidv4(),
    name: "Payout",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Transfers",
        route: "/dashboard/payout/transfer",
        icon: <BalancesIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: 'Pending approval',
      //   route: '/dashboard/payout/pendingapproval',
      //   icon: <PendingApprovalIcon />,
      // },
      {
        id: uuidv4(),
        name: "Beneficiaries",
        route: "/dashboard/payout/beneficiaries",
        icon: <UserIcon />,
      },
      {
        id: uuidv4(),
        name: "Funding history",
        route: "/dashboard/payout/funding-history",
        icon: <BalanceHistoryIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Refund",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Refunds",
        route: "/dashboard/business/refunds",
        icon: <RefundIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Chargeback",
    subRoutes: [
      {
        id: uuidv4(),
        name: "All chargebacks",
        route: "/dashboard/chargeback",
        icon: <AllChargeBackIcon />,
      },
      {
        id: uuidv4(),
        name: "Pending",
        route: "/dashboard/chargeback/pending",
        icon: <PendingIcon />,
      },
      {
        id: uuidv4(),
        name: "Awaiting response",
        route: "/dashboard/chargeback/awaiting-response",
        icon: <AwaitingResponseIcon />,
      },
      {
        id: uuidv4(),
        name: "Won",
        route: "/dashboard/chargeback/won",
        icon: <WonIcon />,
      },
      {
        id: uuidv4(),
        name: "Lost",
        route: "/dashboard/chargeback/lost",
        icon: <LostIcon />,
      },
      {
        id: uuidv4(),
        name: "Assessment",
        route: "/dashboard/chargeback/assessment",
        icon: <AssessmentIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Settings",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Business",
        route: "/dashboard/settings/business",
        icon: <ProfileIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Compliance Information",
      //   route: "/dashboard/settings/compliance-information",
      //   icon: <ComplianceIcon />,
      // },
      {
        id: uuidv4(),
        name: "Settlement Accounts",
        route: "/dashboard/settings/settlement-accounts",
        icon: <PayoutAccountIcon />,
      },
      {
        id: uuidv4(),
        name: "Roles & Permissions",
        route: "/dashboard/settings/roles-permissions",
        icon: <RolesIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: 'Subsidiaries',
      //   route: '/dashboard/settings/subsidiaries',
      //   icon: <UserIcon />,
      // },
      {
        id: uuidv4(),
        name: "Users",
        route: "/dashboard/settings/users",
        icon: <UserIcon />,
      },
      {
        id: uuidv4(),
        name: "Preferences",
        route: "/dashboard/settings/preference",
        icon: <PreferencesIcon />,
      },
      {
        id: uuidv4(),
        name: "Audit Trails",
        route: "/dashboard/settings/audit-trails",
        icon: <AuditIcon />,
      },
      {
        id: uuidv4(),
        name: "API Keys & Webhooks",
        route: "/dashboard/settings/api-webhooks",
        icon: <ApiIcon />,
      },
    ],
  },
];

export const activeNav = {
  fontFamily: "'Kumbh Sans'",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.011em",
  color: "#016E20 !important",
  background: "#EDFAF1",
};
