import React, { useState, Fragment, useEffect } from "react";
import styles from "./Invoice.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { InvoiceData, ColumnInvoice } from "../../types/TablesTypes/Invoice";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import invoiceApi from "../../api/invoice";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import Modal from "react-modal";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/white-invoice.svg";
import { ReactComponent as DropArrow } from "../../assets/IconsMerchant/export.svg";
import { customStyles } from "../../constants/modalStyles";
import { useHistory } from "react-router-dom";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import InvoiceMenu from "../../components/MenuOption/InvoiceMenu/InvoiceMenu";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";

const Invoice = () => {
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState<any>();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const getInvoicesApi = useApi(invoiceApi.getInvoices);
  const [valueStatus, setValueStatus] = useState("");

  const history = useHistory();

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [status, setStatus] = useState("");
  const [reference, setReference] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customeName, setCustomeName] = useState("");
  const [title, setTitle] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setReference("");
    setCustomeName("");
    setCustomerEmail("");
    setTitle("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Successful" },
        { name: "Pending" },
        { name: "Failed" },
        { name: "Awaiting-Confirmation" },
      ],
    },
    {
      id: 2,
      name: "Customer Email",
      value: customerEmail,
      setValue: setCustomerEmail,
    },
    {
      id: 3,
      name: "Customer Name",
      value: customeName,
      setValue: setCustomeName,
    },
    {
      id: 4,
      name: "Invoice Reference",
      value: reference,
      setValue: setReference,
    },
    {
      id: 5,
      name: "Invoice Title",
      value: title,
      setValue: setTitle,
    },
  ];

  const [modalIsOpen, setIsOpen] = React.useState(true);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchInvoices = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/invoice/all?status=${status}
        &status=${status}
        &reference=${reference}
        &customerName=${customeName}
        &title=${title}
        &customerEmail=${customerEmail}
        &status=${status}
        &fromdate=${fromDate}&todate=${toDate}`
      );
      setInvoice(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchInvoices();
  }, [bearer]);

  const dataTransaction = () => {
    const tempArr: InvoiceData[] = [];
    invoice?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((invoice: any, index: number) => {
        tempArr.push({
          title: invoice?.title,
          reference: invoice?.reference,
          customerName: invoice?.customerName,
          amount: `${invoice?.currency} ${numberWithCommas(invoice?.amount)}`,
          dueDate: moment(invoice?.dueDate).format("MMMM Do YYYY, h:mm a"),
          status: (
            <StatusView
              status={invoice?.status}
              green="Successful"
              red="Failed"
              orange="Pending"
            />
          ),
          action: <InvoiceMenu invoiceData={invoice} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [invoice?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Invoices"
        dataLength={invoice?.data?.items.length}
        data={invoice?.data?.items}
        value={value}
        dropdown={dropdown}
        setDropdown={setDropdown}
        setValue={setValue}
        newButton={
          <div
            className={styles.createLink}
            onClick={() => {
              history.push("invoice/new-invoice");
            }}
          >
            <DropArrow className={styles.link} />
            New Invoice
          </div>
          // <div
          //   className={styles.createLink}
          //   onClick={() => {
          //     history.push("invoice/new-invoice");
          //   }}
          // >
          //   <DropArrow className={styles.link} />
          //   New Invoice
          // </div>
        }
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      {getInvoicesApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnInvoice ? ColumnInvoice : []}
          emptyPlaceHolder="You currently do not have any invoices"
          total={invoice?.data?.items.length}
        />
      )}
    </Fragment>
  );
};

export default Invoice;
