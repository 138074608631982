import React from 'react';

export interface FundingHistoryData {
	date: string;
	method: string;
	fee: string;
	transactionReference: string;
	amount: string;
	action: any;
}

export const ColumnFundingHistory = [
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Method',
		accessor: 'method',
	},
	{
		Header: 'Session ID',
		accessor: 'transactionReference',
	},
	{
		Header: 'Fee',
		accessor: 'fee',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
